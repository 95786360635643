import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FAQ.css';
import faqleft from '../assets/faqleft.png';
import faqright from '../assets/faqright.png';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import { Link, useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import HeroSection from './HeroSection';
const Faq = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  //
  // const { t } = useTranslation();
  const [openDropdowns, setOpenDropdowns] = useState(Array(7).fill(false));

  const toggleDropdown = (index) => {
    setOpenDropdowns(openDropdowns.map((open, i) => (i === index ? !open : open)));
  };

  return (
    // <>
    // <HeroSection
    //       heroSliderSettings={heroSliderSettings}
    //       heroSlides={heroSlides}
    //       splitText={splitText}
    //     />
    <div className="faq-container">
      {/* <div className="faq-left">
        <img src={faqleft} alt="Left" />
      </div> */}
      <div className="faq-middle">
        <h1 className="faq-title">
          {t('faq.title')}
          <hr className="faq-underline" />
        </h1>
        <h1 className="faq-subtitle">
          {t('faq.subtitle')}
          <hr className="faq-underline" />
        </h1>
        <table className="faq-table">
          <tbody>
            {Array(7).fill().map((_, index) => (
              <tr key={index}>
                <td>
                  <div className="faq-dropdown">
                    <button className="faq-toggle" onClick={() => toggleDropdown(index)}>
                      {openDropdowns[index] ? '-' : '+'} {t(`faq.question${index + 1}.title`)}
                    </button>
                    {openDropdowns[index] && (
                      <div className="faq-content" style={{justifyContent:"center"}}>
                        {t(`faq.question${index + 1}.content`)}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="faq-right">
        <img src={faqright} alt="Right" />
      </div> */}
    </div>
    // </>
  );
};

export default Faq;
