// TranslationExample.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Whyus.css';
import aboutImage from '../assets/whyUs.png';

const WhyUs = () => {
  const { t } = useTranslation();

  return (
    <div className="translation-example-whyus">
     <div className="left-section">
        <img src={aboutImage} alt="Example" className="image" />
      </div>
      <div className="right-section">
        <h1 className='about'>{t('whyus.title')}</h1>
        <h2 className='about'>{t('whyus.subtitle')}</h2>
        <p className='about'>{t('whyus.paragraph')}</p>
        <button className='about'>{t('whyus.buttonText')}</button>
      </div>
      
    </div>
  );
};

export default WhyUs;
