import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import products from '../../productsData'; // Adjust the path as necessary
import logos from '../../logosData'; // استيراد الشعارات
import './ElectronicServices.css';

Modal.setAppElement('#root');

const ElectronicServices = () => {
  const { t, i18n } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('منصة قوى');

  const categories = [
    'منصة قوى',
    'التامينات الأجتماعية',
    'وزارة التجارة',
    'منصة بلدي',
    'منصة ابشر و مقيم',
    'الزكاة و الضريبة',
    'منصة سلامة',
    'منصة مدد',
    'وزارة النقل'
  ];

  const filteredProducts = products.filter(product => product.category === selectedCategory).slice(0, 4);

  const openModal = (service) => {
    setCurrentService(service);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentService(null);
  };

  const handleRequestService = () => {
    const message = encodeURIComponent(`أرغب في طلب الخدمة التالية: ${currentService.title}`);
    const phoneNumber = '966554061714';
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <div className="electronic-services">
      <h1>الخدمات الإلكترونية</h1>
      <p>نحن نحرص على تقديم أفضل الخدمات الإلكترونية لضمان سهولة الإجراءات وسرعة الإنجاز. نقدم مجموعة متنوعة من الخدمات الإلكترونية لتلبية احتياجاتكم المختلفة، وتسهيل الوصول إلى المعلومات وإنجاز المعاملات بكفاءة عالية.</p>
      
      <div className="intro-section">
        <img src="https://via.placeholder.com/600x400" alt="شرح الخدمات الإلكترونية" className="intro-image" />
        <p>تمكنكم الخدمات الإلكترونية من توفير الوقت والجهد، وتسهيل عمليات التقديم والاستفسار عن الخدمات المختلفة عبر الإنترنت. نقدم خدمات شاملة تشمل إدارة الأعمال، والخدمات الحكومية، والتأمينات، والزكاة والضرائب، وغيرها الكثير. فريقنا مستعد دائمًا لتقديم الدعم والمساعدة لضمان رضاكم الكامل.</p>
      </div>

      <h2>منتجات ذات صلة</h2>
      <div className="category-buttons">
        {categories.map(category => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      
      <div className="products-list">
        {filteredProducts.map(product => (
          <div key={product.id} className="product-item">
            <img src={product.image} alt={product.name} className="product-image" />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <button className="request-button">طلب الخدمة</button>
          </div>
        ))}
      </div>
      
      {currentService && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Service Details"
          className={`service-modal ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}
          overlayClassName="service-overlay"
        >
          <h2>{currentService.title}</h2>
          <p>{currentService.description}</p>
          <table className="service-details">
            <thead>
              <tr>
                <th>{t('service_name')}</th>
                <th>{t('service_time')}</th>
              </tr>
            </thead>
            <tbody>
              {currentService.details.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.name}</td>
                  <td>{detail.time}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="modal-buttons">
            <button onClick={closeModal}>{t('close')}</button>
            <button onClick={handleRequestService}>{t('request_service')}</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ElectronicServices;
