import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import Subscriptions from './components/Subscriptions';
import Contact from './components/Contact';
import Products from './components/Products';
import ProductDetails from './components/ProductDetails';
import ServiceDetails from './components/ServiceDetails'; // استيراد صفحة تفاصيل الخدمة الجديدة
import Header from './components/Header';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import FooterNew from './components/FooterNew';
import WhatsAppButton from './components/WhatsAppButton';
import { useTranslation } from 'react-i18next';

// Importing new service pages
import InvestorServices from './components/ServicePages/InvestorServices';
import EstablishmentServices from './components/ServicePages/EstablishmentServices';
import ZakatServices from './components/ServicePages/ZakatServices';
import LegalServices from './components/ServicePages/LegalServices';
import ConsultationServices from './components/ServicePages/ConsultationServices';
import SupportServices from './components/ServicePages/SupportServices';
import CorrectionServices from './components/ServicePages/CorrectionServices';
import ElectronicServices from './components/ServicePages/ElectronicServices';
import WpsServices from './components/ServicePages/WpsServices';
import LaborCalculator from './components/LaborCalculator'; // استيراد الصفحة الجديدة
import Faq from './components/Faq';
import FAQComponent from './components/FAQComponent';
import LaborCaseServices from './components/ServicePages/LaborCaseServices';
import LegalAdvisor from './components/ServicePages/LegalAdvisor';
import FollowUpServices from './components/ServicePages/FollowUpServices';

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Router>
      <div className={`App ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
        {/* <TopBar /> */}
        <Header changeLanguage={changeLanguage} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/investors" element={<InvestorServices />} />
            <Route path="/services/establishment" element={<EstablishmentServices />} />
            <Route path="/services/zakat" element={<ZakatServices />} />
            <Route path="/services/legal" element={<LegalServices />} />
            <Route path="/services/labor-case" element={<LaborCaseServices />} />
            <Route path="/services/legal-advisor" element={<LegalAdvisor />} />
            <Route path="/services/follow-up" element={<FollowUpServices />} />
            <Route path="/services/consultation" element={<ConsultationServices />} />
            <Route path="/services/support" element={<SupportServices />} />
            <Route path="/services/correction" element={<CorrectionServices />} />
            <Route path="/services/electronic" element={<ElectronicServices />} />
            <Route path="/services/wps" element={<WpsServices />} />
            <Route path="/labor-calculator" element={<LaborCalculator />} /> {/* إضافة المسار الجديد */}
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/products" element={<Products />} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/faq2" element={<FAQComponent/>} />
            <Route path="/product/:productId" element={<ProductDetails />} />
            <Route path="/service/:serviceId" element={<ServiceDetails />} /> {/* المسار لتفاصيل الخدمة */}
          </Routes>
        </div>
        <FooterNew/>
        <WhatsAppButton />
      </div>
    </Router>
  );
}

export default App;

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './components/Home';
// import Services from './components/Services';
// import Subscriptions from './components/Subscriptions';
// import Contact from './components/Contact';
// import Products from './components/Products';
// import ProductDetails from './components/ProductDetails';
// import Header from './components/Header';
// import TopBar from './components/TopBar';
// import Footer from './components/Footer';
// import WhatsAppButton from './components/WhatsAppButton';
// import { useTranslation } from 'react-i18next';


// import InvestorServices from './components/ServicePages/InvestorServices';
// import EstablishmentServices from './components/ServicePages/EstablishmentServices';
// import ZakatServices from './components/ServicePages/ZakatServices';
// import LegalServices from './components/ServicePages/LegalServices';
// import ConsultationServices from './components/ServicePages/ConsultationServices';
// import SupportServices from './components/ServicePages/SupportServices';
// import CorrectionServices from './components/ServicePages/CorrectionServices';
// import ElectronicServices from './components/ServicePages/ElectronicServices';
// import WpsServices from './components/ServicePages/WpsServices';
// import LaborCalculator from './components/LaborCalculator'; // استيراد الصفحة الجديدة
// import FooterNew from './components/FooterNew';

// function App() {
//   const { i18n } = useTranslation();

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//   };

//   return (
//     <Router>
//       <div className={`App ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
//         {/* <TopBar /> */}
//         <Header changeLanguage={changeLanguage} />
//         <div className="content">
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/services" element={<Services />} />
//             <Route path="/services/investors" element={<InvestorServices />} />
//             <Route path="/services/establishment" element={<EstablishmentServices />} />
//             <Route path="/services/zakat" element={<ZakatServices />} />
//             <Route path="/services/legal" element={<LegalServices />} />
//             <Route path="/services/consultation" element={<ConsultationServices />} />
//             <Route path="/services/support" element={<SupportServices />} />
//             <Route path="/services/correction" element={<CorrectionServices />} />
//             <Route path="/services/electronic" element={<ElectronicServices />} />
//             <Route path="/services/wps" element={<WpsServices />} />
//             <Route path="/labor-calculator" element={<LaborCalculator />} /> {/* إضافة المسار الجديد */}
//             <Route path="/subscriptions" element={<Subscriptions />} />
//             <Route path="/contact" element={<Contact />} />
//             <Route path="/products" element={<Products />} />
//             <Route path="/product/:productId" element={<ProductDetails />} />
//             <Route path="/service/:serviceId" element={<ProductDetails />} /> {/* Added path for service details */}
//           </Routes>
//         </div>
//         <FooterNew />
//         <WhatsAppButton />
//       </div>
//     </Router>
//   );
// }

// export default App;
