// src/components/ServiceDetails.js
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import products from '../productsData'; // استيراد بيانات المنتجات كخدمات
import './ServiceDetails.css';
// new design
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import { useTranslation } from 'react-i18next';
import HeroSection from './HeroSection';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons
import { Link, useNavigate } from 'react-router-dom';
const ServiceDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [carouselIndex, setCarouselIndex] = useState(0);
  
    const handleBeforeChange = (oldIndex, newIndex) => {
      setCarouselIndex(newIndex);
    };
    const splitText = (text, wordsPerLine) => {
      const words = text.split(' ');
      const lines = [];
      for (let i = 0; i < words.length; i += wordsPerLine) {
        lines.push(words.slice(i, i + wordsPerLine).join(' '));
      }
      return lines.join('<br />');
    };
    const heroSliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      beforeChange: handleBeforeChange,
      appendDots: (dots) => (
        <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
          {dots}
        </div>
      ),
      customPaging: (i) => (
        <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
          {i + 1}
        </div>
      )
    };
  
    const heroSlides = [
      {
        image: heroImage1,
        title: t(''),
        subtitle: t(''),
      },
      {
        image: heroImage2,
        title: t(''),
        subtitle: t(''),
      },
      {
        image: heroImage3,
        title: t(''),
        subtitle: t(''),
      },
    ];
  
    const handleCardClick = (path) => {
      navigate(path);
    };
    // 
  const { serviceId } = useParams();
  const service = products.find((service) => service.id === parseInt(serviceId));

  if (!service) {
    return <div>الخدمة غير موجودة</div>;
  }

  const handleRequest = () => {
    window.location.href = `https://wa.me/966554061714?text=أرغب في طلب ${service.name} ومدة الخدمة ${service.duration}.`;
  };

  return (
    <>
    <HeroSection
        heroSliderSettings={heroSliderSettings}
        heroSlides={heroSlides}
        splitText={splitText}
      />
    
    <div className="service-details">
    <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                <FaHome /> {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/services" style={{color:"#172F4E"}}>
                <FaServicestack /> {t('services')}
              </Link>
            </li>
            </ul>
            </div>
      <img src={service.image} alt={service.name} className="service-image" />
      <h2 className="service-name">{service.name}</h2>
      <table className="service-table">
        <tbody>
          <tr>
            <th>الوصف</th>
            <td>{service.description}</td>
          </tr>
          <tr>
            <th>مدة الخدمة</th>
            <td>{service.duration}</td>
          </tr>
        </tbody>
      </table>
      <button onClick={handleRequest} className="button">طلب الخدمة</button>
    </div>
    </>
  );
};

export default ServiceDetails;
