// src/components/ServicePages/CorrectionServices.js
import React from 'react';
import './CorrectionServices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faBalanceScale, faFileAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import heroImage1 from '../../assets/banner2.jpeg';
import heroImage2 from '../../assets/banner2.jpeg';
import heroImage3 from '../../assets/banner2.jpeg';
import  { useState } from 'react';
import HeroSection from '../HeroSection';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const CorrectionServices = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // 
  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="service-page">
      <h1>خدمة تصحيح الأوضاع للمنشآت المخالفة</h1>
      <p>تقديم حلول شاملة للمنشآت التي تواجه مشاكل وتعقيدات قانونية بهدف تصحيح أوضاعها وضمان الامتثال الكامل للقوانين واللوائح.</p>
      
      {/* <div className="intro-section"> */}
        {/* <img src="https://via.placeholder.com/600x400" alt="تصحيح الأوضاع" className="intro-image" /> */}
        <p>
          <strong>أهمية تصحيح الأوضاع:</strong> 
          تصحيح الأوضاع هو خطوة حاسمة لضمان استمرارية العمل وتجنب العقوبات والغرامات القانونية. نحن نقدم استشارات متخصصة وحلول عملية لمساعدة المنشآت على تصحيح أوضاعها والعمل وفقًا للقوانين واللوائح المحلية والدولية.
        </p>
      {/* </div> */}

      <div className="service-icons">
        <FontAwesomeIcon icon={faTools} className="service-icon" />
        <FontAwesomeIcon icon={faBalanceScale} className="service-icon" />
      </div>

      <div className="steps">
        <h2>خطوات تصحيح الأوضاع</h2>
        <div className="steps-list">
          <div className="step">
            <FontAwesomeIcon icon={faFileAlt} className="step-icon" />
            <h3>الخطوة 1</h3>
            <p>تقديم الطلب</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faBalanceScale} className="step-icon" />
            <h3>الخطوة 2</h3>
            <p>مراجعة الوضع القانوني</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faTools} className="step-icon" />
            <h3>الخطوة 3</h3>
            <p>تنفيذ الإجراءات التصحيحية</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faCheckCircle} className="step-icon" />
            <h3>الخطوة 4</h3>
            <p>الحصول على الامتثال</p>
          </div>
        </div>
      </div>

      <h2>خدمات تصحيح الأوضاع</h2>
      <div className="services-list">
        <ul>
          {/* <li>مراجعة وتقييم الوضع الحالي للمنشأة</li>
          <li>تقديم الاستشارات القانونية اللازمة</li>
          <li>إعداد وتقديم المستندات القانونية المطلوبة</li>
          <li>تنفيذ الإجراءات التصحيحية والمتابعة المستمرة</li>
          <li>التواصل مع الجهات الحكومية للحصول على الامتثال</li> */}
          :
<li>_ حجز اسم تجاري</li>
<li>_ تحديد الانشطه المرتبطه بالنشاط</li>
<li>_ اصدار سجل تجاري</li>
<li>_ فتح ملف منشأة في وزارة الموارد البشرية</li>
<li>_ اشتراك الغرفه التجاريه</li>
<li>_ تسجيل المنشأه في العنوان الوطني</li>
<li>_ فتح ملف التأمينات الاجتماعيه</li>
<li>_ تسحيل مشتركي المنشأة في التأمينات</li>
<li>_ التسجيل في منصة قوى</li>
<li>_ صياغة عقود العمل قانونيا وتوثيقها(4 عمال)</li>
<li>_ اصدار تأشيرات العمل بالمهن المطلوبه</li>
<li>_ تفويض التأشيرات من موقع وزارة الخارجيه</li>
<li>_ التسجيل في منصة مدد</li>
<li>_ نقل خدمات العمالة</li>
<li>_ التسجيل في ابشر اعمال</li>
<li>_ إصدار رخصة البلديه</li>
<li>_ إصدار ترخيص سلامة</li>
<li>_ فتح حساب بنكي للمنشأة</li>
<li>_ فتح حساب في الزكاة والضريبه</li>
<li>_ التسجيل في ضريبة القيمة المضافة واصدار رقم ضريبي</li>
        </ul>
      </div>

      <h2>تواصل معنا</h2>
      <p>إذا كنت تواجه أي مشاكل قانونية أو ترغب في تصحيح أوضاع منشأتك، لا تتردد في التواصل معنا. فريقنا المتخصص جاهز لتقديم المساعدة والدعم لضمان امتثال منشأتك للقوانين واللوائح.</p>
      <button className="contact-button">تواصل معنا</button>
    </div>
    </>
  );
};

export default CorrectionServices;
