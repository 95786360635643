import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './Subscriptions.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import { useNavigate } from 'react-router-dom';
import HeroSection from './HeroSection';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons
import { Link } from 'react-router-dom';
import Faq from './Faq';
const Subscriptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  const [currentPackage, setCurrentPackage] = useState('service_packages');
  const [expandedService, setExpandedService] = useState(null);

  const toggleServiceDetails = (service) => {
    setExpandedService(expandedService === service ? null : service);
  };

  const handleSubscription = (packageName) => {
    const message = encodeURIComponent(`I would like to subscribe to ${packageName}`);
    const phoneNumber = '966554061714';
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  const renderServicePackages = () => (

        
    <div className="packages-container">
      <div className="package">
        <div className="package-header sahab-header">
          <h2 className="package-title">{t('الباقة الذهبية')}</h2>
        </div>
        <p className="package-description">{t('comprehensive_package_description')}</p>
        <div className="package-content">
          <table className="subscriptions-table">
            <tbody>
              <tr onClick={() => toggleServiceDetails('absher')}>
                <td><FaCheck /> {t(' منصة أبشر و مقيم')}</td>
                <td className="toggle-icon">
                  {expandedService === 'absher' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'absher' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                    <li>{t("إصدار اقامات")}</li>
                      <li>{t("تجديد إقامات")}</li>
                      <li>{t("تأشيرات خروج و عودة")}</li>
                      <li>{t("خدمة توصيل الوثائق")}</li>
                      <li>{t("تجديد إشتراك أبشر أعمال")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('qiwa')}>
                <td><FaCheck /> {t('منصة قوى')}</td>
                <td className="toggle-icon">
                  {expandedService === 'qiwa' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'qiwa' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      
                      <li>التسجيل بمنصة قوى</li>
                      <li>رخص العمل</li>
                      <li>إدارة المهن</li>
                      <li>إصدار شهادة السعودة</li>
                      <li>إدارة عقود العمل</li>
                      <li>تأشيرات العمل الفوري</li>
                      <li>نقل الخدمات</li>
                      <li>تجديد إشتراك بمنصة قوى</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('baladi')}>
                <td><FaCheck /> {t('منصة بلدي')}</td>
                <td className="toggle-icon">
                  {expandedService === 'baladi' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'baladi' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                    <li>{t("إصدار رخصة تجارية")}</li>
                      <li>{t("تجديد رخصة تجارية")}</li>
                      <li>{t("تعديل رخصة تجارية")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('salama')}>
                <td><FaCheck /> {t('منصة سلامة')}</td>
                <td className="toggle-icon">
                  {expandedService === 'salama' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'salama' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t("إصدار رخصة سلامة")}</li>
                      <li>{t("تجديد رخصة سلامة")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('tamenat')}>
                <td><FaCheck /> {t('خدمات التامينات الأجتماعية')}</td>
                <td className="toggle-icon">
                  {expandedService === 'tamenat' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'tamenat' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t("إستكمال بيانات المنشأة")}</li>
                      <li>{t("إضافة مدير حساب الفروع")}</li>
                      <li>{t("تسجيل الموظفين")}</li>
                      <li>{t("إستكمال بيانات الموظفين")}</li>
                      <li>{t("تحديث الإجور")}</li>
                      <li>{t("إصدارشهادة إلتزام التأمينات")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('tamenat')}>
                <td><FaCheck /> {t("وزارة التجارة")}</td>
                <td className="toggle-icon">
                  {expandedService === 'comirial' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'comirial' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t("إصدار سجل تجاري")}</li>
                      <li>{t("تجديد سجل تجاري")}</li>
                      <li>{t("تعديل سجل تجاري")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('mudd')}>
                <td><FaCheck /> {t('مدد')}</td>
                <td className="toggle-icon">
                  {expandedService === 'mudd' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'mudd' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('نظام حماية الأجور ورفع الرواتب')}</li>
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر الأصلي')}: <span className="original-price">15999</span> {t('ريال سعودي')}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('الخصم')}: <span className="discount">20%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر بعد الخصم')}: <span className="final-price">12799.2</span> {t('ريال سعودي')}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="subscribe-button-container">
          <button className="subscribe-button" onClick={() => handleSubscription(t('الباقة الفضية'))}>
            {t('اشتراك')}
          </button>
        </div>
      </div>

      <div className="package">
        <div className="package-header sahab-header">
          <h2 className="package-title">{t('الباقة البلاتينية')}</h2>
        </div>
        <p className="package-description">{t('comprehensive_package_description')}</p>
        <div className="package-content">
          <table className="subscriptions-table">
            <tbody>
              <tr onClick={() => toggleServiceDetails('absher')}>
                <td><FaCheck /> {t('منصة ابشر ومقيم')}</td>
                <td className="toggle-icon">
                  {expandedService === 'absher' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'absher' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t("إصدار اقامات")}</li>
                      <li>{t("تجديد إقامات")}</li>
                      <li>{t("تأشيرات خروج و عودة")}</li>
                      <li>{t("خدمة توصيل الوثائق")}</li>
                      <li>{t("تجديد إشتراك أبشر أعمال")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('qiwa')}>
                <td><FaCheck /> {t('منصة قوى')}</td>
                <td className="toggle-icon">
                  {expandedService === 'qiwa' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'qiwa' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('رخص العمل')}</li>
                      <li>{t('إدارة المهن')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('baladi')}>
                <td><FaCheck /> {t('منصة بلدي')}</td>
                <td className="toggle-icon">
                  {expandedService === 'baladi' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'baladi' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t("إصدار رخصة تجارية")}</li>
                      <li>{t("تجديد رخصة تجارية")}</li>
                      <li>{t("تعديل رخصة تجارية")}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('salama')}>
                <td><FaCheck /> {t('منصة سلامة')}</td>
                <td className="toggle-icon">
                  {expandedService === 'salama' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'salama' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إدارة كافة الخدمات')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('tamenat')}>
                <td><FaCheck /> {t('التامينات الأجتماعية')}</td>
                <td className="toggle-icon">
                  {expandedService === 'tamenat' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'tamenat' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إدارة كافة الخدمات')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('mudd')}>
                <td><FaCheck /> {t('مدد')}</td>
                <td className="toggle-icon">
                  {expandedService === 'mudd' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'mudd' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('نظام حماية الأجور ورفع الرواتب')}</li>
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر الأصلي')}: <span className="original-price">25999</span> {t('ريال سعودي')}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('الخصم')}: <span className="discount">20%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر بعد الخصم')}: <span className="final-price">20799.2</span> {t('ريال سعودي')}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="subscribe-button-container">
          <button className="subscribe-button" onClick={() => handleSubscription(t('الباقة البلاتينية'))}>
            {t('اشتراك')}
          </button>
          <div className="note-row">
            {t('يتم إضافة 500 ريال لكل موظف زيادة')}
          </div>
        </div>
      </div>
    </div>
  );

  const renderLegalPackages = () => (
    <div className="packages-container">
      <div className="package">
        <div className="package-header legal-header">
          <h2 className="package-title">{t('باقة الإدارة القانونية للموارد البشرية')}</h2>
        </div>
        <p className="package-description">{t('legal_hr_management_package_description')}</p>
        <div className="package-content">
          <table className="subscriptions-table">
            <tbody>
              <tr onClick={() => toggleServiceDetails('legalConsultations')}>
                <td><FaCheck /> {t('الاستشارات القانونية لنظام العمل')}</td>
                <td className="toggle-icon">
                  {expandedService === 'legalConsultations' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'legalConsultations' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('خدمات استشارية من الخبراء')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('contractDrafting')}>
                <td><FaCheck /> {t('صياغة العقود العمالية وفق نظام العمل')}</td>
                <td className="toggle-icon">
                  {expandedService === 'contractDrafting' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'contractDrafting' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('صياغة العقود العمالية')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('violationManagement')}>
                <td><FaCheck /> {t('إدارة المخالفات و الجزائات')}</td>
                <td className="toggle-icon">
                  {expandedService === 'violationManagement' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'violationManagement' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إدارة جميع المخالفات')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('contractTermination')}>
                <td><FaCheck /> {t('إدارة انهاء عقود العمل بما يحفظ حقوق الطريفين')}</td>
                <td className="toggle-icon">
                  {expandedService === 'contractTermination' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'contractTermination' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إدارة انهاء العقود')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('hrProcesses')}>
                <td><FaCheck /> {t('نمذجة عمليات الموارد البشرية وفق نظام العمل')}</td>
                <td className="toggle-icon">
                  {expandedService === 'hrProcesses' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'hrProcesses' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إدارة عمليات الموارد البشرية')}</li>
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر الأصلي')}: <span className="original-price">11999</span> {t('ريال سعودي')}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('الخصم')}: <span className="discount">15%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر بعد الخصم')}: <span className="final-price">10199.15</span> {t('ريال سعودي')}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="subscribe-button-container">
          <button className="subscribe-button" onClick={() => handleSubscription(t('باقة الإدارة القانونية للموارد البشرية'))}>
            {t('اشتراك')}
          </button>
        </div>
      </div>
    </div>
  );

  const renderWageProtectionPackages = () => (
    <div className="packages-container">
      <div className="package">
        <div className="package-header wage-protection-header">
          <h2 className="package-title">{t('باقة حماية الأجور الأساسية')}</h2>
        </div>
        <p className="package-description">{t('wage_protection_basic_package_description')}</p>
        <div className="package-content">
          <table className="subscriptions-table">
            <tbody>
              <tr onClick={() => toggleServiceDetails('salaryReports')}>
                <td><FaCheck /> {t('إعداد تقارير الرواتب')}</td>
                <td className="toggle-icon">
                  {expandedService === 'salaryReports' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'salaryReports' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إعداد تقارير الرواتب الشهرية')}</li>
                      <li>{t('إدارة المدفوعات')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('employeeRecords')}>
                <td><FaCheck /> {t('إدارة سجلات الموظفين')}</td>
                <td className="toggle-icon">
                  {expandedService === 'employeeRecords' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'employeeRecords' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('تحديث سجلات الموظفين')}</li>
                      <li>{t('إدارة السجلات والتقارير')}</li>
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر الأصلي')}: <span className="original-price">8999</span> {t('ريال سعودي')}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('الخصم')}: <span className="discount">10%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر بعد الخصم')}: <span className="final-price">8099.1</span> {t('ريال سعودي')}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="subscribe-button-container">
          <button className="subscribe-button" onClick={() => handleSubscription(t('باقة حماية الأجور الأساسية'))}>
            {t('اشتراك')}
          </button>
        </div>
      </div>

      <div className="package">
        <div className="package-header wage-protection-header">
          <h2 className="package-title">{t('باقة حماية الأجور المتقدمة')}</h2>
        </div>
        <p className="package-description">{t('wage_protection_advanced_package_description')}</p>
        <div className="package-content">
          <table className="subscriptions-table">
            <tbody>
              <tr onClick={() => toggleServiceDetails('salaryReports')}>
                <td><FaCheck /> {t('إعداد تقارير الرواتب')}</td>
                <td className="toggle-icon">
                  {expandedService === 'salaryReports' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'salaryReports' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إعداد تقارير الرواتب الشهرية')}</li>
                      <li>{t('إدارة المدفوعات')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('employeeRecords')}>
                <td><FaCheck /> {t('إدارة سجلات الموظفين')}</td>
                <td className="toggle-icon">
                  {expandedService === 'employeeRecords' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'employeeRecords' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('تحديث سجلات الموظفين')}</li>
                      <li>{t('إدارة السجلات والتقارير')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('complianceReports')}>
                <td><FaCheck /> {t('إعداد تقارير الامتثال')}</td>
                <td className="toggle-icon">
                  {expandedService === 'complianceReports' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'complianceReports' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إعداد تقارير الامتثال الدورية')}</li>
                      <li>{t('متابعة الامتثال للقوانين')}</li>
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر الأصلي')}: <span className="original-price">11999</span> {t('ريال سعودي')}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('الخصم')}: <span className="discount">15%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر بعد الخصم')}: <span className="final-price">10199.15</span> {t('ريال سعودي')}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="subscribe-button-container">
          <button className="subscribe-button" onClick={() => handleSubscription(t('باقة حماية الأجور المتقدمة'))}>
            {t('اشتراك')}
          </button>
        </div>
      </div>

      <div className="package">
        <div className="package-header wage-protection-header">
          <h2 className="package-title">{t('باقة حماية الأجور المميزة')}</h2>
        </div>
        <p className="package-description">{t('wage_protection_premium_package_description')}</p>
        <div className="package-content">
          <table className="subscriptions-table">
            <tbody>
              <tr onClick={() => toggleServiceDetails('salaryReports')}>
                <td><FaCheck /> {t('إعداد تقارير الرواتب')}</td>
                <td className="toggle-icon">
                  {expandedService === 'salaryReports' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'salaryReports' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إعداد تقارير الرواتب الشهرية')}</li>
                      <li>{t('إدارة المدفوعات')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('employeeRecords')}>
                <td><FaCheck /> {t('إدارة سجلات الموظفين')}</td>
                <td className="toggle-icon">
                  {expandedService === 'employeeRecords' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'employeeRecords' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('تحديث سجلات الموظفين')}</li>
                      <li>{t('إدارة السجلات والتقارير')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('complianceReports')}>
                <td><FaCheck /> {t('إعداد تقارير الامتثال')}</td>
                <td className="toggle-icon">
                  {expandedService === 'complianceReports' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'complianceReports' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('إعداد تقارير الامتثال الدورية')}</li>
                      <li>{t('متابعة الامتثال للقوانين')}</li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr onClick={() => toggleServiceDetails('consultation')}>
                <td><FaCheck /> {t('خدمات الاستشارات المالية')}</td>
                <td className="toggle-icon">
                  {expandedService === 'consultation' ? <FaChevronUp /> : <FaChevronDown />}
                </td>
              </tr>
              {expandedService === 'consultation' && (
                <tr>
                  <td colSpan="2">
                    <ul className="service-details-subscribtion">
                      <li>{t('استشارات مالية دورية')}</li>
                      <li>{t('إدارة الميزانية وتقديم النصائح')}</li>
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر الأصلي')}: <span className="original-price">14999</span> {t('ريال سعودي')}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('الخصم')}: <span className="discount">15%</span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="price-row">
                  {t('السعر بعد الخصم')}: <span className="final-price">12749.15</span> {t('ريال سعودي')}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="subscribe-button-container">
          <button className="subscribe-button" onClick={() => handleSubscription(t('باقة حماية الأجور المميزة'))}>
            {t('اشتراك')}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="subscriptions">
   
      <h1 className="subscriptions-title">{t('الباقات')}</h1>
      <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                {/* <FaHome />  */}
                {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/subscriptions" style={{color:"#172F4E"}}>
                {/* <FaServicestack /> */}
                 {t('subscriptions')}
              </Link>
            </li>
            </ul>
            </div>
      <div className="tabs">
        <button
          className={`tab-button ${currentPackage === 'service_packages' ? 'active' : ''}`}
          onClick={() => setCurrentPackage('service_packages')}
        >
          {t('باقة الخدمات')}
        </button>
        {/* <button
          className={`tab-button ${currentPackage === 'legal_packages' ? 'active' : ''}`}
          onClick={() => setCurrentPackage('legal_packages')}
        >
          {t('باقة الإدارة القانونية للموارد البشرية')}
        </button> */}
        <button
          className={`tab-button ${currentPackage === 'wage_protection_packages' ? 'active' : ''}`}
          onClick={() => setCurrentPackage('wage_protection_packages')}
        >
          {t('باقات حماية الأجور')}
        </button>
      </div>
      {currentPackage === 'service_packages' && renderServicePackages()}
      {/* {currentPackage === 'legal_packages' && renderLegalPackages()} */}
      {currentPackage === 'wage_protection_packages' && renderWageProtectionPackages()}
      <Faq/>
    </div>
    </>

  );
};

export default Subscriptions;
// import React from 'react';
// import { useState} from 'react';
// import { useTranslation } from 'react-i18next';
// import { FaCheck } from 'react-icons/fa';
// import './Subscriptions.css';
// import heroImage1 from '../assets/banner2.jpg';
// import heroImage2 from '../assets/banner2.jpg';
// import heroImage3 from '../assets/banner2.jpg';
// import HeroSection from './HeroSection';
// import { Link, useNavigate } from 'react-router-dom';
// const Subscriptions = () => {
//   // const { t } = useTranslation();
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const [carouselIndex, setCarouselIndex] = useState(0);

//   const handleBeforeChange = (oldIndex, newIndex) => {
//     setCarouselIndex(newIndex);
//   };
//   const splitText = (text, wordsPerLine) => {
//     const words = text.split(' ');
//     const lines = [];
//     for (let i = 0; i < words.length; i += wordsPerLine) {
//       lines.push(words.slice(i, i + wordsPerLine).join(' '));
//     }
//     return lines.join('<br />');
//   };
//   const heroSliderSettings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     beforeChange: handleBeforeChange,
//     appendDots: (dots) => (
//       <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
//         {dots}
//       </div>
//     ),
//     customPaging: (i) => (
//       <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
//         {i + 1}
//       </div>
//     )
//   };

//   const heroSlides = [
//     {
//       image: heroImage1,
//       title: t(''),
//       subtitle: t(''),
//     },
//     {
//       image: heroImage2,
//       title: t(''),
//       subtitle: t(''),
//     },
//     {
//       image: heroImage3,
//       title: t(''),
//       subtitle: t(''),
//     },
//   ];

//   const handleCardClick = (path) => {
//     navigate(path);
//   };
//   const handleSubscription = (packageName) => {
//     const message = encodeURIComponent(`I would like to subscribe to ${packageName}`);
//     const phoneNumber = '966554061714';
//     window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
//   };

//   return (
//     <>
//     <HeroSection
//           heroSliderSettings={heroSliderSettings}
//           heroSlides={heroSlides}
//           splitText={splitText}
//         />
//     <div className="subscriptions">
//       <h1 className="subscriptions-title">{t('الباقات')}</h1>

//       <div className="packages-container">
//         <div className="package package-sahab">
//           <h2 className="package-title">{t('باقة سحاب')}</h2>
//           <p className="package-description">{t('comprehensive_package_description')}</p>
//           <div className="package-content">
//             <table className="subscriptions-table">
//               <tbody>
//                 {/* List all the features of "باقة سحاب" here */}
//                 <tr>
//                   <td><FaCheck /> {t('خدمات أبشر')}</td>
//                   <td>{t('إدارة كافة الخدمات عدد (4 موظفين )')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('منصة قوى')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('وزارة التجارة و الغرفة التجارية')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('منصة بلدي')}</td>
//                   <td>{t('إدارة الرخص التجارية')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('خدمات الاستقدام')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('منصة سلامة')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('التامينات الأجتماعية')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('التامين الطبي و المركبات')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('مدد')}</td>
//                   <td>{t('نظام حماية الأجور ورفع الرواتب')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('البنوك والمصارف')}</td>
//                   <td>{t('فتح حساب للرواتب وللموظفين و نقاط البيع')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('وزارة النقل')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('الزكاة والدخل')}</td>
//                   <td>{t('رفع الأقرارات الضربية والزكوية')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('وزارة العدل')}</td>
//                   <td>{t('إدارة كافة الخدمات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('التصنيف المهني')}</td>
//                   <td>{t('تسجيل التصنيف للمهن العليا')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('التجارة الآلكترونية')}</td>
//                   <td>{t('انشاء متجر الكتروني')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('طاقات')}</td>
//                   <td>{t('أدارة كافة الخدمات والدعم الحكومي للموظفين')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('وزارة الطاقة')}</td>
//                   <td>{t('أدارة كافة الخدمات وتراخيص المركبات')}</td>
//                 </tr>
//               </tbody>
//               <tfoot>
//                 <tr>
//                   <td colSpan="2" className="price-row">
//                     {t('السعر الأصلي')}: <span className="original-price">15999</span> {t('ريال سعودي')}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td colSpan="2" className="price-row">
//                     {t('الخصم')}: <span className="discount">20%</span>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td colSpan="2" className="price-row">
//                     {t('السعر بعد الخصم')}: <span className="final-price">12799.2</span> {t('ريال سعودي')}
//                   </td>
//                 </tr>
//               </tfoot>
//             </table>
//           </div>
//           <div className="subscribe-button-container">
//             <button className="subscribe-button" onClick={() => handleSubscription(t('باقة سحاب'))}>
//               {t('اشتراك')}
//             </button>
//           </div>
//         </div>

//         <div className="package package-legal-hr">
//           <h2 className="package-title">{t('باقة الإدارة القانونية للموارد البشرية')}</h2>
//           <p className="package-description">{t('legal_hr_management_package_description')}</p>
//           <div className="package-content">
//             <table className="subscriptions-table">
//               <tbody>
//                 <tr>
//                   <td><FaCheck /> {t('الاستشارات القانونية لنظام العمل')}</td>
//                   <td>{t('خدمات استشارية من الخبراء')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('صياغة العقود العمالية وفق نظام العمل')}</td>
//                   <td>{t('صياغة العقود العمالية')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('إدارة المخالفات و الجزائات')}</td>
//                   <td>{t('إدارة جميع المخالفات')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('إدارة انهاء عقود العمل بما يحفظ حقوق الطريفين')}</td>
//                   <td>{t('إدارة انهاء العقود')}</td>
//                 </tr>
//                 <tr>
//                   <td><FaCheck /> {t('نمذجة عمليات الموارد البشرية وفق نظام العمل')}</td>
//                   <td>{t('إدارة عمليات الموارد البشرية')}</td>
//                 </tr>
//               </tbody>
//               <tfoot>
//                 <tr>
//                   <td colSpan="2" className="price-row">
//                     {t('السعر الأصلي')}: <span className="original-price">11999</span> {t('ريال سعودي')}
//                   </td>
//                 </tr>
//                 <tr>
//                   <td colSpan="2" className="price-row">
//                     {t('الخصم')}: <span className="discount">15%</span>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td colSpan="2" className="price-row">
//                     {t('السعر بعد الخصم')}: <span className="final-price">10199.15</span> {t('ريال سعودي')}
//                   </td>
//                 </tr>
//               </tfoot>
//             </table>
//           </div>
//           <div className="subscribe-button-container">
//             <button className="subscribe-button" onClick={() => handleSubscription(t('باقة الإدارة القانونية للموارد البشرية'))}>
//               {t('اشتراك')}
//             </button>
//           </div>
//         </div>

//       </div>
//     </div>
//     </>
//   );
// };

// export default Subscriptions;
