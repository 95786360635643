import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaRegListAlt } from 'react-icons/fa';
import { BiPhoneCall } from "react-icons/bi";
import './Header.css';
import NewLogo from '../assets/NewLogo.png';

const Header = ({ changeLanguage }) => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`header ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
      <div className="header-content">
      <Link to="/" style={{width:"30%"}}>
        <img src={NewLogo} className='header-logo' alt="Vision 2030 Logo" />
        </Link>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link to="/">
                {/* <FaHome />  */}
                {t('HOME')}
              </Link>
            </li>
            <li className="has-dropdown">
              <span className="dropdown-toggle">
                {/* <FaServicestack /> */}
                <Link to="/services" style={{ color: 'white' }}> {t('services')} </Link>
              </span>
              <ul className="dropdown-menu">
                <li><Link to="/services/legal-advisor" style={{ color: '#00324f' }}>{t("servicesSection.service1.title")}</Link></li>
                <li><Link to="/services/legal" style={{ color: '#00324f' }}>{t("servicesSection.service2.title")}</Link></li>
                <li><Link to="/services" style={{ color: '#00324f' }}>{t("servicesSection.service3.title")}</Link></li>
                <li><Link to="/subscriptions" style={{ color: '#00324f' }}>{t("servicesSection.service4.title")}</Link></li>
                <li><Link to="/services/establishment" style={{ color: '#00324f' }}>{t("servicesSection.service5.title")}</Link></li>
                <li><Link to="/services/correction" style={{ color: '#00324f' }}>{t("servicesSection.service6.title")}</Link></li>
                <li><Link to="/services/legal" style={{ color: '#00324f' }}>{t("servicesSection.service7.title")}</Link></li>
                <li><Link to="/services/labor-case" style={{ color: '#00324f' }}>{t("servicesSection.service8.title")}</Link></li>
                <li><Link to="/services/internal-regulations" style={{ color: '#00324f' }}>{t("servicesSection.service9.title")}</Link></li>
                <li><Link to="/services/admin-structure" style={{ color: '#00324f' }}>{t("servicesSection.service10.title")}</Link></li>
                <li><Link to="/services/follow-up" style={{ color: '#00324f' }}>{t("servicesSection.service11.title")}</Link></li>
                <li><Link to="/services/amicable-settlement" style={{ color: '#00324f' }}>{t("servicesSection.service12.title")}</Link></li>
              </ul>
            </li>
            <li>
              <Link to="/subscriptions">
                {/* <FaRegListAlt />  */}
                {t('subscriptions')}
              </Link>
            </li>
            {/* <li>
              <Link to="/contact">
                <FaRegEnvelope /> {t('contact')}
              </Link>
            </li> */}
            {/* <li>
              <Link to="/labor-calculator">
                <FaCalculator /> {t('Labor Calculator')}
              </Link>
            </li> */}
          </ul>
        </nav>
        <div className='Header-buttons'>
          <a href='tel:+966554061714'>
            <button className="header2-button">
              <BiPhoneCall /> {t('call-us')}
            </button>
          </a>
          <div className="language-switcher">
            {i18n.language === 'ar' ? (
              <button onClick={() => changeLanguage('en')}>
                En
              </button>
            ) : (
              <button onClick={() => changeLanguage('ar')}>
                ع
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;


// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa';
// import { BiPhoneCall } from "react-icons/bi";
// import './Header.css';
// import NewLogo from '../assets/NewLogo.png';
// import LangImage from '../assets/lang2.png';
// import callImage from '../assets/call.png';

// const Header = ({ changeLanguage }) => {
//   const { t, i18n } = useTranslation();
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [servicesOpen, setServicesOpen] = useState(false);
//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };
//   const toggleServices = () => {
//     setServicesOpen(!servicesOpen);
//   };

//   return (
//     <header className={`header ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
//       <div className="header-content">
//         <img src={NewLogo} className='header-logo' alt="Vision 2030 Logo" />
//         <button className="menu-toggle" onClick={toggleMenu}>
//           ☰
//         </button>
//         <nav className={`nav ${menuOpen ? 'open' : ''}`}>
//           <ul>
//             <li>
//               <Link to="/">
//                 <FaHome /> {t('HOME')}
//               </Link>
//             </li>
//             <li className="has-dropdown">
//               <span onClick={toggleServices} className="dropdown-toggle">
//                 <FaServicestack /><Link to="/services"  style={{color:'white'}}> {t('services')} </Link>
//               </span>
//               {servicesOpen && (
//                 <ul className="dropdown-menu">
//                   <li><Link to="/services/legal"  style={{color:'#00324f'}}>   {t("servicesSection.service1.title")}</Link></li>
//                   <li><Link to="/services"  style={{color:'#00324f'}}>   {t("servicesSection.service3.title")}</Link></li>
//                   <li><Link to="/subscriptions"  style={{color:'#00324f'}}>   {t("servicesSection.service4.title")}</Link></li>
//                   <li><Link to="/services/establishment"  style={{color:'#00324f'}}>{t("servicesSection.service5.title")}</Link></li>
//                   <li><Link to="/services/correction"  style={{color:'#00324f'}}>{t("servicesSection.service6.title")}</Link></li>
//                   <li><Link to="/services/legal"  style={{color:'#00324f'}}>{t("servicesSection.service7.title")}</Link></li>
//                   {/* <li><Link to="/services/legal"  style={{color:'#00324f'}}>{t("servicesSection.service1.title")}</Link></li>
//                   <li><Link to="/services/legal"  style={{color:'#00324f'}}>{t("servicesSection.service1.title")}</Link></li> */} 
//                 </ul>
//               )}
//             </li>
//             <li>
//               <Link to="/subscriptions">
//                 <FaRegListAlt /> {t('subscriptions')}
//               </Link>
//             </li>
//             <li>
//               <Link to="/contact">
//                 <FaRegEnvelope /> {t('contact')}
//               </Link>
//             </li>
//             <li>
//               <Link to="/labor-calculator">
//                 <FaCalculator /> {t('Labor Calculator')}
//               </Link>
//             </li>
//             {/* <li>
//               <Link to="/faq2">
//                 <FaQuestionCircle /> {t('FAQ')}
//               </Link>
//             </li> */}
//           </ul>
//         </nav>
//         <div className='Header-buttons'>
//           <a href='tel:+966554061714'>
//             <button className="header2-button">
//               <BiPhoneCall /> {t('call-us')}
//             </button>
//           </a>
//           <div className="language-switcher">
//             {i18n.language === 'ar' ? (
//               <button onClick={() => changeLanguage('en')}>
//                 En
//               </button>
//             ) : (
//               <button onClick={() => changeLanguage('ar')}>
//                 ع
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;
