// src/components/LaborCalculator.js
import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import './LaborCalculator.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import HeroSection from './HeroSection';
import { useTranslation } from 'react-i18next';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons

// import {Link } from 'react-router-dom';
// import HeroSection from './HeroSection';
import { Link, useNavigate } from 'react-router-dom';
const LaborCalculator = () => {
    
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // 
  const [salary, setSalary] = useState('');
  const [allowances, setAllowances] = useState('');
  const [totalSalary, setTotalSalary] = useState(0);
  const [vacationDays, setVacationDays] = useState('');
  const [overtimeDays, setOvertimeDays] = useState('');
  const [overtimeRate, setOvertimeRate] = useState(1.5);
  const [serviceYears, setServiceYears] = useState('');
  const [vacationPay, setVacationPay] = useState(null);
  const [overtimePay, setOvertimePay] = useState(null);
  const [terminationCompensation, setTerminationCompensation] = useState(null);
  const [endOfServiceReward, setEndOfServiceReward] = useState(null);

  const calculateTotalSalary = () => {
    const salaryValue = parseFloat(salary) || 0;
    const allowancesValue = parseFloat(allowances) || 0;
    const total = salaryValue + allowancesValue;
    setTotalSalary(total.toFixed(2));
    return total;
  };

  const calculateVacationPay = () => {
    const total = calculateTotalSalary();
    const result = (total / 30) * vacationDays;
    setVacationPay(result.toFixed(2));
  };

  const calculateOvertimePay = () => {
    const total = calculateTotalSalary();
    const overtimeHours = overtimeDays * 8; // 8 ساعات عمل يومية
    const result = (total / 240) * overtimeHours * overtimeRate;
    setOvertimePay(result.toFixed(2));
  };

  const calculateUnlawfulTerminationCompensation = () => {
    const total = calculateTotalSalary();
    const result = (total * 2) * serviceYears;
    setTerminationCompensation(result.toFixed(2));
  };

  const calculateEndOfServiceReward = () => {
    const total = calculateTotalSalary();
    let result = 0;
    if (serviceYears <= 5) {
      result = (total / 2) * serviceYears;
    } else {
      result = (total / 2) * 5 + total * (serviceYears - 5);
    }
    setEndOfServiceReward(result.toFixed(2));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('تم نسخ الناتج إلى الحافظة');
  };

  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="labor-calculator">
      <h1>حاسبة العمل</h1>
      <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                <FaHome /> {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/labor-calculator" style={{color:"#172F4E"}}>
                <FaServicestack /> {t('حاسبة العمل')}
              </Link>
            </li>
            </ul>
            </div>
      <div className="calculator-section">
        <h2>أجر الإجازة</h2>
        <label>
          الراتب الشهري:
          <input type="number" value={salary} onChange={(e) => setSalary(e.target.value)} />
        </label>
        <label>
          البدلات:
          <input type="number" value={allowances} onChange={(e) => setAllowances(e.target.value)} />
        </label>
        <div className="total-salary">
          <span>المجموع:</span> <span>{totalSalary} ريال</span>
        </div>
        <label>
          عدد أيام الإجازة:
          <input type="number" value={vacationDays} onChange={(e) => setVacationDays(e.target.value)} />
        </label>
        <button onClick={calculateVacationPay}>احسب أجر الإجازة</button>
        {vacationPay !== null && (
          <div className="result">
            <span>أجر الإجازة: {vacationPay} ريال</span>
            <FaCopy onClick={() => copyToClipboard(vacationPay)} className="copy-icon" />
          </div>
        )}
      </div>

      <div className="calculator-section">
        <h2>أجر العمل الإضافي</h2>
        <label>
          الراتب الشهري:
          <input type="number" value={salary} onChange={(e) => setSalary(e.target.value)} />
        </label>
        <label>
          البدلات:
          <input type="number" value={allowances} onChange={(e) => setAllowances(e.target.value)} />
        </label>
        <div className="total-salary">
          <span>المجموع:</span> <span>{totalSalary} ريال</span>
        </div>
        <label>
          عدد الأيام الإضافية:
          <input type="number" value={overtimeDays} onChange={(e) => setOvertimeDays(e.target.value)} />
        </label>
        <label>
          معدل أجر العمل الإضافي:
          <input type="number" value={overtimeRate} onChange={(e) => setOvertimeRate(e.target.value)} />
        </label>
        <button onClick={calculateOvertimePay}>احسب أجر العمل الإضافي</button>
        {overtimePay !== null && (
          <div className="result">
            <span>أجر العمل الإضافي: {overtimePay} ريال</span>
            <FaCopy onClick={() => copyToClipboard(overtimePay)} className="copy-icon" />
          </div>
        )}
      </div>

      <div className="calculator-section">
        <h2>التعويض عن الإنهاء لغير سبب مشروع</h2>
        <label>
          الراتب الشهري:
          <input type="number" value={salary} onChange={(e) => setSalary(e.target.value)} />
        </label>
        <label>
          البدلات:
          <input type="number" value={allowances} onChange={(e) => setAllowances(e.target.value)} />
        </label>
        <div className="total-salary">
          <span>المجموع:</span> <span>{totalSalary} ريال</span>
        </div>
        <label>
          عدد سنوات الخدمة:
          <input type="number" value={serviceYears} onChange={(e) => setServiceYears(e.target.value)} />
        </label>
        <button onClick={calculateUnlawfulTerminationCompensation}>احسب التعويض</button>
        {terminationCompensation !== null && (
          <div className="result">
            <span>التعويض عن الإنهاء لغير سبب مشروع: {terminationCompensation} ريال</span>
            <FaCopy onClick={() => copyToClipboard(terminationCompensation)} className="copy-icon" />
          </div>
        )}
      </div>

      <div className="calculator-section">
        <h2>مكافأة نهاية الخدمة</h2>
        <label>
          الراتب الشهري:
          <input type="number" value={salary} onChange={(e) => setSalary(e.target.value)} />
        </label>
        <label>
          البدلات:
          <input type="number" value={allowances} onChange={(e) => setAllowances(e.target.value)} />
        </label>
        <div className="total-salary">
          <span>المجموع:</span> <span>{totalSalary} ريال</span>
        </div>
        <label>
          عدد سنوات الخدمة:
          <input type="number" value={serviceYears} onChange={(e) => setServiceYears(e.target.value)} />
        </label>
        <button onClick={calculateEndOfServiceReward}>احسب المكافأة</button>
        {endOfServiceReward !== null && (
          <div className="result">
            <span>مكافأة نهاية الخدمة: {endOfServiceReward} ريال</span>
            <FaCopy onClick={() => copyToClipboard(endOfServiceReward)} className="copy-icon" />
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default LaborCalculator;
