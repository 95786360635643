import React from 'react';
import './FeatureBox.css';
import { useTranslation } from 'react-i18next';
import faIconName1 from '../assets/ficon1.png';
import faIconName2 from '../assets/ficon2.png';
import faIconName3 from '../assets/ficon3.png';

const FeatureBox = () => {
  const { t } = useTranslation();

  const boxes = [
    {
      image: faIconName3,
      title: t('featureBox.title3'),
      subtitle: t('featureBox.subtitle3'),
    },
    {
      image: faIconName2,
      title: t('featureBox.title2'),
      subtitle: t('featureBox.subtitle2'),
    },
    {
      image: faIconName1,
      title: t('featureBox.title1'),
      subtitle: t('featureBox.subtitle1'),
    },
  ];

  return (
    <div className="three-boxes">
      {boxes.map((box, index) => (
        <div key={index} className="box">
          <img src={box.image} alt={`Icon ${index + 1}`} />
          <h2 className="title">{box.title}</h2>
          <p className="subtitle">{box.subtitle}</p>
        </div>
      ))}
    </div>
  );
};

export default FeatureBox;
