import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FooterNew.css';
import NewLogo from '../assets/NewLogo.png';
import callImage from '../assets/call.png';
import visionImage from '../assets/vision.jpeg';
import visionImage2 from '../assets/vision2.png';
import logos from '../logosData';
import { BiPhoneCall } from 'react-icons/bi';
// Font Awesome imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const FooterNew = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, message } = formData;
    const whatsappMessage = `mailto:email@example.com&body=${encodeURIComponent(`Email: ${email}\nMessage: ${message}`)}`;
    window.location.href = whatsappMessage;
  };

  return (
    <footer className="footer">
      <div className="top-section">
        <h2 className="footer-title">{t('footer.title')}</h2>
        <a href='https://wa.me/966554061714?text=Hello%2C%20I%20would%20like%20to%20inquire%20about%20your%20services'>
        <button className="footer-button">{t('footer.buttonText')}</button>
        </a>
      </div>
      <div className="bottom-section">
        <div className="footer-section">
          <img src={NewLogo} alt="Logo" className="footer-logo" />
          <ul className="footer-links">
            <li><a href="/">{t('footer.link1')}</a></li>
            <li><a href="/">{t('footer.link2')}</a></li>
            <li><a href="/">{t('footer.link3')}</a></li>
            <li><a href="/">{t('footer.link4')}</a></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <div className="footerImage">
            <img src={visionImage} alt="vision" style={{width:"70%"}} />
           
          </div>
          <a href='tel:+966554061714'>
          <button  className="footer2-button">
          <BiPhoneCall /> {t('call-us')}
          </button>
          </a>
        </div>

        <div className="footer-section">
        <img src={visionImage2} alt="vision" style={{width:"70%"}} />
          {/* <h3>{t('footer.formTitle')}</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder={t('footer.emailPlaceholder')}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder={t('footer.messagePlaceholder')}
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className='form-button'>{t('footer.sendButton')}</button>
          </form> */}
        </div>
      </div>
      
      <div className="third-section">
        {/* <h2 className="footer-title">{t('footer.title')}</h2> */}
        <p>{t('footer.copyright')}</p>
        <ul className="social-icons">
          <li><a href="https://facebook.com" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a></li>
          <li><a href="https://twitter.com" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a></li>
          <li><a href="https://instagram.com" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a></li>
          <li><a href="https://linkedin.com" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a></li>
        </ul>
      </div>
    </footer>
  );
};

export default FooterNew;
