// src/components/WhatsAppButton.js
import React from 'react';
import './WhatsAppButton.css';
import whatsappIcon from '../assets/whatsapp.png'; // تأكد من المسار الصحيح

const WhatsAppButton = () => {
  const phoneNumber = '966554061714';
  const message = encodeURIComponent('Hello, I would like to inquire about your services.');

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${message}`}
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={whatsappIcon} alt="WhatsApp" />
    </a>
  );
};

export default WhatsAppButton;
