import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTools,
  faShieldAlt,
  faBuilding,
  faHandsHelping,
  faHandshake,
  faBalanceScale,
  faGavel,
  faUserShield,
  faUserCog,
  faMoneyCheckAlt,
  faTrademark,
} from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Home.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import products from '../productsData';
import logos from '../logosData';
import FeatureBox from './FeatureBox';
import About from './About';
import WhyUs from './WhyUs';
import Faq from './Faq';
import CountUp from './CountUp';
import HeroSection from './HeroSection';
import CompanyLocation from './CompanyLocation';
import ServicesOffered from './ServicesOffered';
import ServicesHome from './ServicesHome';
const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t('welcome1'),
      subtitle: t('hero_subtitle1'),
    },
    {
      image: heroImage2,
      title: t('welcome2'),
      subtitle: t('hero_subtitle2'),
    },
    {
      image: heroImage3,
      title: t('welcome3'),
      subtitle: t('hero_subtitle3'),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="home">
      {/* <div className="hero">
        <Slider {...heroSliderSettings}>
          {heroSlides.map((slide, index) => (
            <div key={index} className="hero-slide">
              <img src={slide.image} alt="Hero Slide" className="hero-image" />
              <div className="hero-text">
                <h1 dangerouslySetInnerHTML={{ __html: splitText(slide.title, 3) }}></h1>
                <p dangerouslySetInnerHTML={{ __html: splitText(slide.subtitle, 5) }}></p>
              </div>
            </div>
          ))}
        </Slider>
      </div> */}
      <HeroSection
        heroSliderSettings={heroSliderSettings}
        heroSlides={heroSlides}
        splitText={splitText}
      />
      {/* <FeatureBox/> */}
      <About/>
      <div className="etmaam-services">
        <div className="etmaam-services-cards">
          {/* Cards */}
        </div>
      </div>
      
      <ServicesOffered/>
      {/* <ServicesHome/> */}
      {/* <ServicesDropdown/> */}
      {/* <div className="services-offered">
        <h3 style={{color:'#00324f'}} className='services-title'>{t("servicesSection.title")}</h3>
        <h1>{t("servicesSection.subtitle")}</h1>
        <div className="services-cards">
          <div className="service-card">
            <FontAwesomeIcon icon={faGavel} className="service-icon" />
            <h3 style={{color:'#00324f'}}>مستشار قانوني لنظام العمل</h3>
            <p>تقديم استشارات قانونية متخصصة في نظام العمل السعودي لضمان توافق شركتكم مع اللوائح والقوانين الحالية.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/legal')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faShieldAlt} className="service-icon" />
            <h3 style={{color:'#00324f'}}>الخدمات القانونية</h3>
            <p>خدمات قانونية شاملة تشمل العقود، النزاعات، والاستشارات القانونية لضمان حماية حقوق شركتكم.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/legal')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTools} className="service-icon" />
            <h3 style={{color:'#00324f'}}>الخدمات الإلكترونية (أفراد - منشات)</h3>
            <p>تقديم خدمات إلكترونية متنوعة تشمل الأفراد والمنشآت لتسهيل إنجاز المعاملات والإجراءات بكفاءة.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/electronic')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faUserShield} className="service-icon" />
            <h3 style={{color:'#00324f'}}>باقة الاشتراك السنوية</h3>
            <p>اشتراك سنوي يشمل مجموعة واسعة من الخدمات بخصومات حصرية وضمان دعم مستمر على مدار العام.</p>
            <button className="request-button" onClick={() => handleCardClick('/subscriptions')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faBuilding} className="service-icon" />
            <h3 style={{color:'#00324f'}}>تأسيس المنشآت</h3>
            <p>خدمات متكاملة لتأسيس المنشآت تشمل التسجيل والترخيص والإرشادات القانونية لضمان بداية قوية ومؤسسة على أسس صحيحة.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/establishment')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTools} className="service-icon" />
            <h3 style={{color:'#00324f'}}>تصحيح أوضاح المنشات</h3>
            <p>خدمات تصحيح الأوضاع للمنشآت المخالفة لضمان التوافق مع الأنظمة والقوانين السعودية وتجنب العقوبات.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/correction')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTrademark} className="service-icon" />
            <h3 style={{color:'#00324f'}}>تسجيل العلامات التجارية</h3>
            <p>حماية العلامات التجارية لشركتكم من خلال خدمات التسجيل القانوني لضمان حقوق الملكية الفكرية.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/legal')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faMoneyCheckAlt} className="service-icon" />
            <h3 style={{color:'#00324f'}}>أعمال المحكمة العمالية</h3>
            <p>تقديم خدمات تمثيل الشركات والأفراد في المحكمة العمالية لضمان حماية الحقوق وتحقيق العدالة.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/labor-court')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faBalanceScale} className="service-icon" />
            <h3 style={{color:'#00324f'}}>اللائحة والأنظمة الداخلية</h3>
            <p>إعداد ومراجعة اللوائح والأنظمة الداخلية للشركات لضمان التوافق مع اللوائح والقوانين وتحقيق العدالة الداخلية.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/internal-regulations')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faUserCog} className="service-icon" />
            <h3 style={{color:'#00324f'}}>إعداد الهيكلة الإدارية</h3>
            <p>خدمات إعداد الهيكلة الإدارية للشركات لضمان تنظيم فعال وتحقيق الأداء الأمثل والانسجام الداخلي.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/admin-structure')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faHandsHelping} className="service-icon" />
            <h3 style={{color:'#00324f'}}>خدمة تحسين العلاقة التعاقدية</h3>
            <p>خدمات تحسين العلاقة التعاقدية بين الشركات والموظفين لضمان بيئة عمل إيجابية وفعالة.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/contractual-relationship')}>قراءة المزيد</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faHandshake} className="service-icon" />
            <h3 style={{color:'#00324f'}}>خدمة التسوية الودية</h3>
            <p>خدمات التسوية الودية للنزاعات بين الشركات والأفراد لضمان حلول سريعة وعادلة دون اللجوء إلى المحاكم.</p>
            <button className="request-button" onClick={() => handleCardClick('/services/amicable-settlement')}>قراءة المزيد</button>
          </div>
        </div>
      </div> */}
      <WhyUs/>
      {/* <Faq/> */}
      {/* <CountUp/> */}
      <div className="logo-carousel">
        <div className="logos">
          <img src={logos['منصة ابشر']} alt="أبشر" />
          <img src={logos['منصة مدد']} alt="مُدد" />
          {/* <img src={logos['وزارة الخارجية']} alt="وزارة الخارجية" /> */}
          <img src={logos['منصة بلدي']} alt="بلدي" />
          <img src={logos['منصة إيجار']} alt="إيجار" />
          <img src={logos['منصة قوى']} alt="قوى" />
          <img src={logos['الزكاة و الضريبة']} alt="هيئة الزكاة والضريبة والجمارك" />
          {/* <img src={logos['وزارة التجارة']} alt="وزارة التجارة" /> */}
          {/* <img src={logos['وزارة النقل']} alt="وزارة النقل" /> */}
          {/* <img src={logos['وزارة العمل والموارد البشرية']} alt="الموارد البشرية" /> */}
          <img src={logos['بنك التنمية الاجتماعي']} alt="بنك التنمية" />
          <img src={logos['مجلس الضمان الصحي']} alt="ضمان صحي" />
          <img src={logos['منصة مساند']} alt="مساند" />
          <img src={logos['منصة ناجز']} alt="ناجز" />
          <img src={logos['منصة سلامة']} alt="سلامة" />
          <img src={logos['التامينات الأجتماعية']} alt="التأمينات الاجتماعية" />
        </div>
      </div>
      {/* <CompanyLocation/> */}
    </div>
  );
};

export default Home;



