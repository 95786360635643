// src/components/ServicePages/InvestorServices.js
import React from 'react';
import products from '../../productsData'; // تعديل المسار للاستيراد بشكل صحيح
import './ServicePages.css'; // تعديل المسار للاستيراد بشكل صحيح
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

const InvestorServices = () => {
  const investorProducts = products.filter(product => product.category === 'خدمات المستثمرين');

  return (
    <div className="service-page">
      <h1>خدمات المستثمرين</h1>
      <p>توفير خدمات متنوعة للمستثمرين لضمان نجاحهم في السوق السعودي.</p>
      <div className="service-icons">
        <FontAwesomeIcon icon={faUserTie} className="service-icon" />
      </div>
      <div className="products-list">
        {investorProducts.map(product => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>السعر: {product.price} ريال</p>
            <p>المدة: {product.duration}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvestorServices;
