// src/components/ServicePages/EstablishmentServices.js
import React from 'react';
import products from '../../productsData';
import './EstablishmentServices.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faHandshake, faFileAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import heroImage1 from '../../assets/banner2.jpeg';
import heroImage2 from '../../assets/banner2.jpeg';
import heroImage3 from '../../assets/banner2.jpeg';
import  { useState } from 'react';
import HeroSection from '../HeroSection';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const EstablishmentServices = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // 
  const commerceProducts = products.filter(product => product.category === 'وزارة التجارة').slice(0, 4);

  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="service-page">
      <h1>خدمات المنشآت</h1>
      <p>نقدم مجموعة شاملة من الخدمات لدعم المنشآت في جميع مراحل تطورها. سواء كنت بصدد تأسيس شركة جديدة أو تبحث عن توسيع نشاطك، فإن فريقنا المتخصص هنا لمساعدتك في كل خطوة على الطريق.</p>
      
      {/* <div className="intro-section"> */}
        {/* <img src="https://via.placeholder.com/600x400" alt="استثمار في المملكة" className="intro-image" /> */}
        <p>
          <strong>أهمية الاستثمار في المملكة:</strong> 
          المملكة العربية السعودية تقدم بيئة استثمارية متميزة تتيح للمستثمرين المحليين والدوليين فرصًا فريدة. مع الإصلاحات الاقتصادية الجارية ورؤية 2030، هناك العديد من الفرص الاستثمارية في مختلف القطاعات مثل التكنولوجيا، والطاقة المتجددة، والسياحة، والصناعة.
        </p>
      {/* </div> */}

      <div className="service-icons">
        <FontAwesomeIcon icon={faBuilding} className="service-icon" />
      </div>

      <div className="steps">
        <h2>خطوات البدء في التجارة وفتح شركتك</h2>
        <div className="steps-list">
          <div className="step">
            <FontAwesomeIcon icon={faFileAlt} className="step-icon" />
            <h3>الخطوة 1</h3>
            <p>تقديم الطلب</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faHandshake} className="step-icon" />
            <h3>الخطوة 2</h3>
            <p>اجتماع مع المستشار</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faCheckCircle} className="step-icon" />
            <h3>الخطوة 3</h3>
            <p>استكمال الإجراءات</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faBuilding} className="step-icon" />
            <h3>الخطوة 4</h3>
            <p>بدء العمل</p>
          </div>
        </div>
      </div>

      <h2>خدمات وزارة التجارة</h2>
      <div className="products-list">
        {commerceProducts.map(product => (
          <div key={product.id} className="product-card" >
            <img src={product.image} alt={product.name} className="product-image" />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>السعر: {product.price} ريال</p>
            <p>المدة: {product.duration}</p>
            <button className="request-button">طلب الخدمة</button>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default EstablishmentServices;
