import React from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import './CountUp.css';

const Countup = () => {
  const { t } = useTranslation();

  return (
    <div className="stats-container">
      <h1 className="stats-title">{t('stats.title')}</h1>
      <div className="stats-blocks">
        <div className="stats-block">
          <div className="stats-count">
            <CountUp end={1500} duration={3} />+
          </div>
          <div className="stats-description">{t('stats.projects')}</div>
        </div>
        <div className="stats-block">
          <div className="stats-count">
            <CountUp end={26} duration={3} />+
          </div>
          <div className="stats-description">{t('stats.govEntities')}</div>
        </div>
        <div className="stats-block">
          <div className="stats-count">
            +<CountUp end={200} duration={3} />
          </div>
          <div className="stats-description">{t('stats.successPartners')}</div>
        </div>
        <div className="stats-block">
          <div className="stats-count">
            +<CountUp end={356} duration={3} />
          </div>
          <div className="stats-description">{t('stats.clients')}</div>
        </div>
      </div>
    </div>
  );
};

export default Countup;
