// src/components/ServicePages/ZakatServices.js
import React from 'react';
import products from '../../productsData'; // تعديل المسار للاستيراد بشكل صحيح
import './ServicePages.css'; // تعديل المسار للاستيراد بشكل صحيح
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

const ZakatServices = () => {
  const zakatProducts = products.filter(product => product.category === 'الزكاة والضريبة');

  return (
    <div className="service-page">
      <h1>الزكاة والضريبة</h1>
      <p>توفير خدمات الزكاة والضريبة لضمان الالتزام بالقوانين واللوائح.</p>
      <div className="service-icons">
        <FontAwesomeIcon icon={faBalanceScale} className="service-icon" />
      </div>
      <div className="products-list">
        {zakatProducts.map(product => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>السعر: {product.price} ريال</p>
            <p>المدة: {product.duration}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ZakatServices;
