import logos from './logosData'; // استيراد الشعارات من logosData

const products = [
  // منصة قوى
  { id: 1, category: 'منصة قوى', name: 'تسجيل بمنصة قوى', description: 'تسجيل بمنصة قوى', duration: '1 يوم', image: logos['منصة قوى'] },
  { id: 2, category: 'منصة قوى', name: 'رخص العمل', description: 'إصدار رخص العمل', duration: '2 يوم', image: logos['منصة قوى'] },
  { id: 3, category: 'منصة قوى', name: 'إدارة المهن', description: 'إدارة المهن', duration: '3 يوم', image: logos['منصة قوى'] },
  { id: 4, category: 'منصة قوى', name: 'إصدار شهادة السعودة', description: 'إصدار شهادة السعودة', duration: '1 يوم', image: logos['منصة قوى'] },
  { id: 5, category: 'منصة قوى', name: 'إدارة عقود العمل', description: 'إدارة عقود العمل', duration: '5 يوم', image: logos['منصة قوى'] },
  { id: 6, category: 'منصة قوى', name: 'لائحة تنظيم العمل', description: 'لائحة تنظيم العمل', duration: '2 يوم', image: logos['منصة قوى'] },
  { id: 7, category: 'منصة قوى', name: 'تأشيرات العمل الفوري', description: 'تأشيرات العمل الفوري', duration: '4 يوم', image: logos['منصة قوى'] },
  { id: 8, category: 'منصة قوى', name: 'نقل الخدمات', description: 'نقل الخدمات', duration: '3 يوم', image: logos['منصة قوى'] },
  { id: 9, category: 'منصة قوى', name: 'تجديد اشتراك بمنصة قوى', description: 'تجديد اشتراك بمنصة قوى', duration: '7 يوم', image: logos['منصة قوى'] },
  { id: 10, category: 'منصة قوى', name: 'حاسبة مكافأة نهاية الخدمة', description: 'حاسبة مكافأة نهاية الخدمة', duration: '2 يوم', image: logos['منصة قوى'] },
  { id: 11, category: 'منصة قوى', name: 'حجز موعد لزيارة مكتب العمل', description: 'حجز موعد لزيارة مكتب العمل', duration: '1 يوم', image: logos['منصة قوى'] },
  { id: 12, category: 'منصة قوى', name: 'اصدار التقارير الشهرية', description: 'اصدار التقارير الشهرية', duration: '2 يوم', image: logos['منصة قوى'] },
  { id: 13, category: 'منصة قوى', name: 'اصدار نسبة الامتثال في المنشأة', description: 'اصدار نسبة الامتثال في المنشأة', duration: '3 يوم', image: logos['منصة قوى'] },
  { id: 14, category: 'منصة قوى', name: 'مخالفات المنشأة', description: 'مخالفات المنشأة', duration: '4 يوم', image: logos['منصة قوى'] },
  { id: 15, category: 'منصة قوى', name: 'نطاقات ورصيد الاستقطاب', description: 'نطاقات ورصيد الاستقطاب', duration: '5 يوم', image: logos['منصة قوى'] },

  // التامينات الأجتماعية
  { id: 16, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'استكمال بيانات المنشأة', description: 'استكمال بيانات المنشأة', duration: '1 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 17, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'إضافة مدير حساب الفروع', description: 'إضافة مدير حساب الفروع', duration: '2 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 18, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'تعديل بيانات المنشأة', description: 'تعديل بيانات المنشأة', duration: '3 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 19, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'تسجيل موظفين', description: 'تسجيل موظفين', duration: '1 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 20, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'استكمال بيانات الموظفين', description: 'استكمال بيانات الموظفين', duration: '5 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 21, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'طلب تقسيط مديونيات التأمينات', description: 'طلب تقسيط مديونيات التأمينات', duration: '2 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 22, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'تحديث أجور', description: 'تحديث أجور', duration: '4 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 23, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'تعديل الكيان القانوني للمنشأة', description: 'تعديل الكيان القانوني للمنشأة', duration: '3 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 24, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'إدارة مشرفي المنشأة', description: 'إدارة مشرفي المنشأة', duration: '7 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 25, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'إدارة ملاك المنشأة', description: 'إدارة ملاك المنشأة', duration: '2 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 26, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'إصدار شهادة التزام التأمينات', description: 'إصدار شهادة التزام التأمينات', duration: '1 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },
  { id: 27, category: "المؤسسة العامة للتأمينات الإجتماعية", name: 'إعفاء من غرامات التأخير', description: 'إعفاء من غرامات التأخير', duration: '2 يوم', image: logos["المؤسسة العامة للتأمينات الإجتماعية"] },

  // وزارة التجارة
  { id: 28, category: 'وزارة التجارة', name: 'اصدار سجل تجاري', description: 'اصدار سجل تجاري', duration: '1 يوم', image: logos['وزارة التجارة'] },
  { id: 29, category: 'وزارة التجارة', name: 'تجديد سجل تجاري', description: 'تجديد سجل تجاري', duration: '2 يوم', image: logos['وزارة التجارة'] },
  { id: 30, category: 'وزارة التجارة', name: 'تعديل سجل تجاري', description: 'تعديل سجل تجاري', duration: '3 يوم', image: logos['وزارة التجارة'] },
  { id: 31, category: 'وزارة التجارة', name: 'حجز اسم تجاري', description: 'حجز اسم تجاري', duration: '1 يوم', image: logos['وزارة التجارة'] },
  { id: 32, category: 'وزارة التجارة', name: 'شطب السجل التجاري', description: 'شطب السجل التجاري', duration: '5 يوم', image: logos['وزارة التجارة'] },
  { id: 33, category: 'وزارة التجارة', name: 'اصدار مستخرج السجل التجاري', description: 'اصدار مستخرج السجل التجاري', duration: '2 يوم', image: logos['وزارة التجارة'] },
  { id: 34, category: 'وزارة التجارة', name: 'موائمة الشركات', description: 'موائمة الشركات', duration: '4 يوم', image: logos['وزارة التجارة'] },
  { id: 35, category: 'وزارة التجارة', name: 'اصدار تراخيص التخفيضات', description: 'اصدار تراخيص التخفيضات', duration: '3 يوم', image: logos['وزارة التجارة'] },
  { id: 36, category: 'وزارة التجارة', name: 'التقييم الذاتي لمستوى حوكمة الشركات', description: 'التقييم الذاتي لمستوى حوكمة الشركات', duration: '7 يوم', image: logos['وزارة التجارة'] },

  // منصة بلدي
  { id: 37, category: 'منصة بلدي', name: 'إصدار رخصة تجارية', description: 'إصدار رخصة تجارية', duration: '1 يوم', image: logos['منصة بلدي'] },
  { id: 38, category: 'منصة بلدي', name: 'تجديد رخصة تجارية', description: 'تجديد رخصة تجارية', duration: '2 يوم', image: logos['منصة بلدي']  },
  { id: 39, category: 'منصة بلدي', name: 'إلغاء رخصة تجارية', description: 'إلغاء رخصة تجارية', duration: '3 يوم', image: logos['منصة بلدي']  },
  { id: 40, category: 'منصة بلدي', name: 'تعديل رخصة تجارية', description: 'تعديل رخصة تجارية', duration: '1 يوم', image: logos['منصة بلدي']  },
  { id: 41, category: 'منصة بلدي', name: 'طباعة رمز الاستجابة', description: 'طباعة رمز الاستجابة', duration: '5 يوم', image: logos['منصة بلدي']  },
  { id: 42, category: 'منصة بلدي', name: 'نقل ملكية رخصة تجارية', description: 'نقل ملكية رخصة تجارية', duration: '2 يوم', image: logos['منصة بلدي']  },
  { id: 43, category: 'منصة بلدي', name: 'إصدار شهادات صحية', description: 'إصدار شهادات صحية', duration: '4 يوم', image: logos['منصة بلدي']  },
  { id: 44, category: 'منصة بلدي', name: 'تجديد شهادات صحية', description: 'تجديد شهادات صحية', duration: '3 يوم', image: logos['منصة بلدي']  },
  { id: 45, category: 'منصة بلدي', name: 'الاعتراض على المخالفات', description: 'الاعتراض على المخالفات', duration: '7 يوم', image: logos['منصة بلدي']  },
  { id: 46, category: 'منصة بلدي', name: 'اصدار تصريح بالانشطة التجارية للعمل 24 ساعة', description: 'اصدار تصريح بالانشطة التجارية للعمل 24 ساعة', duration: '2 يوم', image: logos['منصة بلدي']  },
  { id: 47, category: 'منصة بلدي', name: 'اصدار شهادة واثق', description: 'اصدار شهادة واثق', duration: '1 يوم', image: logos['منصة بلدي']  },

  // منصة ابشر و مقيم
  { id: 48, category: 'منصة ابشر و مقيم', name: 'إصدار اقامات', description: 'إصدار اقامات', duration: '1 يوم', image: logos['منصة ابشر']  },
  { id: 49, category: 'منصة ابشر و مقيم', name: 'تجديد اقامات', description: 'تجديد اقامات', duration: '2 يوم', image: logos['منصة ابشر'] },
  { id: 50, category: 'منصة ابشر و مقيم', name: 'تأشيرة خروج وعودة', description: 'تأشيرة خروج وعودة', duration: '3 يوم', image: logos['منصة ابشر'] },
  { id: 51, category: 'منصة ابشر و مقيم', name: 'تأشيرة خروج نهائي', description: 'تأشيرة خروج نهائي', duration: '1 يوم', image: logos['منصة ابشر'] },
  { id: 52, category: 'منصة ابشر و مقيم', name: 'إسقاط عامل خرج ولم يعد', description: 'إسقاط عامل خرج ولم يعد', duration: '5 يوم', image: logos['منصة ابشر'] },
  { id: 53, category: 'منصة ابشر و مقيم', name: 'خدمة توصيل الوثائق', description: 'خدمة توصيل الوثائق', duration: '2 يوم', image: logos['منصة ابشر'] },
  { id: 54, category: 'منصة ابشر و مقيم', name: 'تسجيل في مقيم', description: 'تسجيل في مقيم', duration: '4 يوم', image: logos['منصة ابشر'] },
  { id: 55, category: 'منصة ابشر و مقيم', name: 'تجديد اشتراك أبشر اعمال', description: 'تجديد اشتراك أبشر اعمال', duration: '3 يوم', image: logos['منصة ابشر'] },
  { id: 56, category: 'منصة ابشر و مقيم', name: 'تقرير مقيم', description: 'تقرير مقيم', duration: '7 يوم', image: logos['منصة ابشر'] },
  { id: 57, category: 'منصة ابشر و مقيم', name: 'تحديث المعلومات', description: 'تحديث المعلومات', duration: '2 يوم', image: logos['منصة ابشر'] },
  { id: 58, category: 'منصة ابشر و مقيم', name: 'تعديل الاسم المترجم', description: 'تعديل الاسم المترجم', duration: '1 يوم', image: logos['منصة ابشر'] },

  // الزكاة و الضريبة
  { id: 59, category: "هيئة الزكاة و الضريبة والجمارك", name: 'التسجيل في ضريبة القيمة المضافة', description: 'التسجيل في ضريبة القيمة المضافة', duration: '1 يوم', image: logos['الزكاة و الضريبة'] },
  { id: 60, category: "هيئة الزكاة و الضريبة والجمارك", name: 'رفع الإقرارات الضريبية ربع السنوية', description: 'رفع الإقرارات الضريبية ربع السنوية', duration: '2 يوم', image:logos['الزكاة و الضريبة']},
  { id: 61, category: "هيئة الزكاة و الضريبة والجمارك", name: 'رفع الإقرارات الزكوية التقديرية', description: 'رفع الإقرارات الزكوية التقديرية', duration: '3 يوم', image:logos['الزكاة و الضريبة']},
  { id: 62, category: "هيئة الزكاة و الضريبة والجمارك", name: 'رفع طلب خطط التقسيط', description: 'رفع طلب خطط التقسيط', duration: '1 يوم', image:logos['الزكاة و الضريبة']},
  { id: 63, category: "هيئة الزكاة و الضريبة والجمارك", name: 'إصدار شهادة الزكاة', description: 'إصدار شهادة الزكاة', duration: '5 يوم', image:logos['الزكاة و الضريبة']},
  { id: 64, category: "هيئة الزكاة و الضريبة والجمارك", name: 'إصدار شهادة ضريبة القيمة المضافة', description: 'إصدار شهادة ضريبة القيمة المضافة', duration: '2 يوم', image:logos['الزكاة و الضريبة']},
  { id: 65, category: "هيئة الزكاة و الضريبة والجمارك", name: 'تحديث بيانات الرقم المميز', description: 'تحديث بيانات الرقم المميز', duration: '4 يوم', image:logos['الزكاة و الضريبة']},
  { id: 66, category: "هيئة الزكاة و الضريبة والجمارك", name: 'التسجيل في الزكاة والدخل', description: 'التسجيل في الزكاة والدخل', duration: '3 يوم', image:logos['الزكاة و الضريبة']},

  // منصة سلامة
  { id: 67, category: 'منصة سلامة', name: 'إصدار رخصة سلامة', description: 'إصدار رخصة سلامة', duration: '1 يوم', image: logos['منصة سلامة'] },
  { id: 68, category: 'منصة سلامة', name: 'تجديد رخصة سلامة', description: 'تجديد رخصة سلامة', duration: '2 يوم', image: logos['منصة سلامة']},
  { id: 69, category: 'منصة سلامة', name: 'تحديث بيانات رخصة سلامة', description: 'تحديث بيانات رخصة سلامة', duration: '3 يوم', image: logos['منصة سلامة'] },

  // منصة مدد
  { id: 70, category: 'منصة مدد', name: 'التسجيل بمنصة مدد', description: 'التسجيل بمنصة مدد', duration: '1 يوم', image: logos['منصة مدد'] },
  { id: 71, category: 'منصة مدد', name: 'رفع الرواتب بشكل شهري', description: 'رفع الرواتب بشكل شهري', duration: '2 يوم', image: logos['منصة مدد'] },
  { id: 72, category: 'منصة مدد', name: 'تحديث بيانات الموظفين', description: 'تحديث بيانات الموظفين', duration: '3 يوم', image: logos['منصة مدد']},
  { id: 73, category: 'منصة مدد', name: 'إزالة ملاحظة حماية الأجور', description: 'إزالة ملاحظة حماية الأجور', duration: '1 يوم', image: logos['منصة مدد'] },

  // وزارة النقل
  { id: 74, category: "وزارة النقل و الخدمات اللوجستية", name: 'اصدارو تجديد بطاقة تشغيل', description: 'اصدارو تجديد بطاقة تشغيل', duration: '1 يوم', image: logos['وزارة النقل'] },
  { id: 75, category:  "وزارة النقل و الخدمات اللوجستية", name: 'اصدارو تجديد بطاقة سائق', description: 'اصدارو تجديد بطاقة سائق', duration: '2 يوم', image: logos['وزارة النقل'] },

  // بنك التنمية الاجتماعي
  { id: 76, category: 'بنك التنمية الاجتماعية', name: 'تقديم طلب قرض اجتماعي', description: 'تقديم طلب قرض اجتماعي', duration: '3 يوم', image: logos['بنك التنمية الاجتماعي'] },
  { id: 77, category: 'بنك التنمية الاجتماعية', name: 'تقديم طلب قرض زواج', description: 'تقديم طلب قرض زواج', duration: '3 يوم', image: logos['بنك التنمية الاجتماعي'] },
  { id: 78, category: 'بنك التنمية الاجتماعية', name: 'تقديم طلب قرض ترميم', description: 'تقديم طلب قرض ترميم', duration: '3 يوم', image: logos['بنك التنمية الاجتماعي'] },
  
  // منصة مساند
  { id: 79, category: 'منصة مساند', name: 'تسجيل حساب مساند', description: 'تسجيل حساب مساند', duration: '1 يوم', image: logos['منصة مساند'] },
  { id: 80, category: 'منصة مساند', name: 'طلب إصدار تأشيرة عمالة منزلية', description: 'طلب إصدار تأشيرة عمالة منزلية', duration: '2 يوم', image: logos['منصة مساند'] },
  
  // منصة ناجز
  { id: 81, category: 'منصة ناجز', name: 'طلب إصدار وكالة إلكترونية', description: 'طلب إصدار وكالة إلكترونية', duration: '1 يوم', image: logos['منصة ناجز'] },
  { id: 82, category: 'منصة ناجز', name: 'طلب تسجيل عقار', description: 'طلب تسجيل عقار', duration: '2 يوم', image: logos['منصة ناجز'] },
  
  // وزارة العمل والموارد البشرية
  { id: 83, category: "وزارة العمل والموارد البشرية الإجتماعية", name: 'طلب إصدار تأشيرة عمل', description: 'طلب إصدار تأشيرة عمل', duration: '2 يوم', image: logos['وزارة العمل والموارد البشرية'] },
  { id: 84, category: "وزارة العمل والموارد البشرية الإجتماعية", name: 'تسجيل منشأة جديدة', description: 'تسجيل منشأة جديدة', duration: '3 يوم', image: logos['وزارة العمل والموارد البشرية'] },

  // منصة إيجار
  { id: 85, category: 'منصة إيجار', name: 'توثيق عقود الإيجار', description: 'توثيق عقود الإيجار', duration: '2 يوم', image: logos['منصة إيجار'] },
  { id: 86, category: 'منصة إيجار', name: 'سداد و موافقة على العقود', description: 'سداد و موافقة على العقود', duration: '3 يوم', image: logos['منصة إيجار'] },

  
  // وزارة الخارجية 
  { id: 87, category: 'وزارة الخارجية', name: 'تاشيرات الزيارة ', description: 'تاشيرات الزيارة', duration: '2 يوم', image: logos['وزارة الخارجية'] },
];

export default products;
