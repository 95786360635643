// src/logosData.js

// استيراد الشعارات
import absher from './assets/absher.svg';
import almoardalbashreah from './assets/almoardalbashreah.svg';
import altejarh from './assets/altejarh.svg';
import baldi from './assets/baladi-logo.svg';
import bankaltnmeah from './assets/bankaltnmeah.svg';
import demanSahe from './assets/demansahe.png';
import mudd from './assets/mudd.svg';
import musand from './assets/musand.svg';
import najez from './assets/najez.svg';
import naqel from './assets/naqel.svg';
import quwa from './assets/quwa.svg';
import salmah from './assets/salmah.svg';
import tamenat from './assets/tamenat.svg';
import zakah from './assets/zakah1.png';
import ejar from './assets/ejar.svg';
import mofa from './assets/mofa.svg';

// شعار افتراضي
import defaultLogo from './assets/absher.svg';

// إنشاء كائن يحتوي على جميع الشعارات
const logos = {
  'منصة قوى': quwa,
  'منصة إيجار': ejar,
  'مجلس الضمان الصحي': demanSahe,
  "المؤسسة العامة للتأمينات الإجتماعية": tamenat,
  'وزارة التجارة': altejarh,
  'منصة بلدي': baldi,
  'منصة ابشر': absher,
  "هيئة الزكاة و الضريبة والجمارك": zakah,
  'منصة سلامة': salmah,
  'بنك التنمية الاجتماعية': bankaltnmeah,
  'منصة مدد': mudd,
  'منصة مساند': musand,
  'منصة ناجز': najez,
  "وزارة النقل و الخدمات اللوجستية": naqel,
  'وزارة الخارجية': mofa,
  "وزارة العمل والموارد البشرية الإجتماعية": almoardalbashreah,
  default: defaultLogo // شعار افتراضي
};

export default logos;
