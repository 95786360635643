import React from 'react';
import Slider from 'react-slick';
import './HeroSection.css';

const HeroSection = ({ heroSliderSettings, heroSlides, splitText }) => {
  return (
    <div className="hero">
      <Slider {...heroSliderSettings}>
        {heroSlides.map((slide, index) => (
          <div key={index} className="hero-slide">
            <img src={slide.image} alt="Hero Slide" className="hero-image" />
            <div className="hero-text">
              <h1 dangerouslySetInnerHTML={{ __html: splitText(slide.title, 3) }}></h1>
              <p dangerouslySetInnerHTML={{ __html: splitText(slide.subtitle, 5) }}></p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSection;
