// src/components/Contact.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import HeroSection from './HeroSection';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons
// import {Link } from 'react-router-dom';
const Contact = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // const { t } = useTranslation();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // هنا يمكنك إضافة منطق إرسال النموذج
    alert('Form submitted');
  };

  return ( <>
  <HeroSection
    heroSliderSettings={heroSliderSettings}
    heroSlides={heroSlides}
    splitText={splitText}
  />
    <div className="contact-page">
      <h1>{t('contact')}</h1>
      <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                <FaHome /> {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/contact" style={{color:"#172F4E"}}>
                <FaServicestack /> {t('contact')}
              </Link>
            </li>
            </ul>
            </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">{t('name')}</label>
          <input
            type="text"
            id="name"
            name="name"
            value={form.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">{t('message')}</label>
          <textarea
            id="message"
            name="message"
            value={form.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">{t('send')}</button>
      </form>
    </div>
    </>
  );
};

export default Contact;
