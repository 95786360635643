import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faGavel, faShieldAlt, faTools, faUserShield, faBuilding, faTrademark, faMoneyCheckAlt, faBalanceScale, faUserCog, faHandsHelping, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ServicesOffered = () => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCardClick = (url) => {
    navigate(url);
  };

  return (
    <div className="services-offered">
      <h3 style={{color:'#00324f'}} className='services-title'>{t("servicesSection.title")}</h3>
      {/* <h1 onClick={toggleDropdown} style={{cursor: 'pointer'}}>
        {t("servicesSection.subtitle")}
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" style={{ marginLeft: '10px' }} />
      </h1> */}
      {/* {dropdownOpen && ( */}
        <div className="services-cards">
          <div className="service-card">
            <FontAwesomeIcon icon={faGavel} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service1.title")}</h3>
            <p>{t("servicesSection.service1.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/legal-advisor')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faShieldAlt} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service2.title")}</h3>
            <p>{t("servicesSection.service2.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/legal')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTools} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service3.title")}</h3>
            <p>{t("servicesSection.service3.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faUserShield} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service4.title")}</h3>
            <p>{t("servicesSection.service4.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/subscriptions')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faBuilding} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service5.title")}</h3>
            <p>{t("servicesSection.service5.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/establishment')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTools} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service6.title")}</h3>
            <p>{t("servicesSection.service6.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/correction')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faTrademark} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service7.title")}</h3>
            <p>{t("servicesSection.service7.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/legal')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faMoneyCheckAlt} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service8.title")}</h3>
            <p>{t("servicesSection.service8.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/labor-case')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faBalanceScale} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service9.title")}</h3>
            <p>{t("servicesSection.service9.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/internal-regulations')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faUserCog} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service10.title")}</h3>
            <p>{t("servicesSection.service10.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/admin-structure')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faHandsHelping} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service11.title")}</h3>
            <p>{t("servicesSection.service11.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/follow-up')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
          <div className="service-card">
            <FontAwesomeIcon icon={faHandshake} className="service-icon" />
            <h3 style={{color:'#00324f'}}>{t("servicesSection.service12.title")}</h3>
            <p>{t("servicesSection.service12.description")}</p>
            <button className="request-button" onClick={() => handleCardClick('/services/amicable-settlement')} style={{position:'relative', right:"30%"}}>{t("servicesSection.readMore")}</button>
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default ServicesOffered;
