// src/components/ServicePages/LegalServices.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import './LegalServices.css';
import products from '../../productsData'; // Adjust the path as necessary
import legalLogo from '../../assets/saudi-arabia.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faGavel, faShieldAlt, faTools, faUserShield, faBuilding, faTrademark, faMoneyCheckAlt, faBalanceScale, faUserCog, faHandsHelping, faHandshake } from '@fortawesome/free-solid-svg-icons';
import heroImage1 from '../../assets/banner2.jpeg';
import heroImage2 from '../../assets/banner2.jpeg';
import heroImage3 from '../../assets/banner2.jpeg';

import HeroSection from '../HeroSection';
import { Link, useNavigate } from 'react-router-dom';
Modal.setAppElement('#root');

const FollowUpServices = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // 

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('الخدمات القانونية');

  const categories = [
    'الخدمات القانونية',
    'الاستشارات القانونية',
    'إعداد العقود',
    'التوثيق والتسجيل',
    'حل النزاعات'
  ];

  const filteredProducts = products.filter(product => product.category === selectedCategory).slice(0, 4);

  const openModal = (service) => {
    setCurrentService(service);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentService(null);
  };

  const handleRequestService = () => {
    const message = encodeURIComponent(`أرغب في طلب الخدمة التالية: ${currentService.title}`);
    const phoneNumber = '966554061714';
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="legal-services">
      <h1>{t("servicesSection.service11.title")}</h1>
      {/* <FontAwesomeIcon icon={faGavel} className="service-icon" /> */}
      <p>نحن نقدم مجموعة شاملة من الخدمات القانونية التي تلبي احتياجاتكم المختلفة. سواء كنتم بحاجة إلى استشارات قانونية، إعداد عقود، أو حل النزاعات، فإن فريقنا المتخصص هنا لمساعدتكم.</p>
      <p>تساعدكم خدماتنا القانونية في حماية حقوقكم وتجنب المشاكل القانونية من خلال تقديم استشارات متخصصة وإعداد الوثائق القانونية بشكل احترافي. فريقنا لديه خبرة واسعة في مختلف المجالات القانونية ومستعد لتقديم أفضل الحلول لكم.</p>
      <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
      <div className="products-list">
      <div  className="product-card">
           <div>
            <h3>وازرة الإعلام</h3>
            </div>
            <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
          </div>
          <div  className="product-card">
           
           <h3>وازرة التجارة</h3>
        
           <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
         </div>
         <div  className="product-card">
           
           <h3> وازرة الموارد البشرية والتنمية الإجتماعية</h3>
        
           <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
         </div>
         <div  className="product-card">
           
           <h3>  وازرة الشؤون البلدية والقروية و الإسكان</h3>
           <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
         </div>
         <div  className="product-card">
           
           <h3> الإدارة العامة للجوازات</h3>
           <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
         </div>
         <div  className="product-card">
           
           <h3> هيئة الذكاة والضريبة والجمارك</h3>
           <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
         </div>
         <div  className="product-card">
           
           <h3>  خدمات التأمين التعاوني</h3>
           <div className='legal-button'> 
      <button className="request-button" onClick={handleRequestService}>{t('request_service')}</button>
      </div>
         </div>
          </div>
      </div>
      </>
     
  );
};

export default FollowUpServices;
