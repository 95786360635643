import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import products from '../productsData'; // Import the products data and use it as services
import logos from '../logosData'; // Import logos data
import './Services.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import { useTranslation } from 'react-i18next';
import HeroSection from './HeroSection';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons
const categories = [...new Set(products.map((product) => product.category))];

const Services = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const selectedCardRef = useRef(null);

  useEffect(() => {
    if (selectedCardRef.current) {
      selectedCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const filteredServices = selectedCategory
    ? products.filter((service) => service.category === selectedCategory)
    : [];

  return (
    <>
  <HeroSection
        heroSliderSettings={heroSliderSettings}
        heroSlides={heroSlides}
        splitText={splitText}
      />
      
    <div className="services">
      <div className='ServicesHero'>
      <h2>الخدمات</h2>
      <a href="/products"  rel="noreferrer">
      {/* <FontAwesomeIcon icon="fa-solid fa-bars" /> */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{width:"15px",padding:"10px"}}><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>
          </a>
          </div>
          <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                {/* <FaHome />  */}
                {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/services" style={{color:"#172F4E"}}>
                {/* <FaServicestack />  */}
                {t('services')}
              </Link>
            </li>
            </ul>
            </div>
      {selectedCategory && <div className="overlay active" onClick={() => setSelectedCategory(null)}></div>}
      <div className="categories-container">
        <div className="categories-list">
          {categories.map((category) => (
            <motion.div
              key={category}
              className={`category-card ${category === selectedCategory ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
              layout
              initial={{ borderRadius: "12px" }}
              animate={{ borderRadius: "12px", width: category === selectedCategory ? "100%" : "calc(33.33% - 20px)" }}
              transition={{ duration: 0.3 }}
              ref={category === selectedCategory ? selectedCardRef : null}
            >
              <img src={logos[category] || logos.default} alt={category} className="category-logo" />
              <p>{category}</p>
              <AnimatePresence>
                {category === selectedCategory && (
                  <motion.div
                    className="services-dropdown"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="services-grid">
                      {filteredServices.map((service) => (
                        <div key={service.id} className="service-item">
                          <h3>{service.name}</h3>
                          <Link to={`/service/${service.id}`} className="button">طلب الآن</Link>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Services;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import products from '../productsData'; // Import the products data and use it as services
// import logos from '../logosData'; // Import logos data
// import './Services.css';

// const categories = ['الكل', ...new Set(products.map((product) => product.category))];

// const Services = () => {
//   const [selectedCategory, setSelectedCategory] = useState('الكل');
//   const [searchTerm, setSearchTerm] = useState('');

//   const filteredServices = products.filter((service) => {
//     return (
//       (selectedCategory === 'الكل' || service.category === selectedCategory) &&
//       service.name.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//   });

//   return (
//     <div className="services">
//       <h2>الخدمات</h2>
//       <div className="categories-slider">
//         {categories.map((category) => (
//           <div
//             key={category}
//             className={`category-item ${category === selectedCategory ? 'active' : ''}`}
//             onClick={() => setSelectedCategory(category)}
//           >
//             {category !== 'الكل' && <img src={logos[category] || logos.default} alt={category} className="category-image" />}
//             <p>{category}</p>
//           </div>
//         ))}
//       </div>
//       <input
//         type="text"
//         placeholder="ابحث عن الخدمة..."
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-input"
//       />
//       <div className="service-list">
//         {filteredServices.map((service) => (
//           <div key={service.id} className="service-card">
//             <img src={service.image} alt={service.name} className="service-image" />
//             <h3>{service.name}</h3>
//             <Link to={`/service/${service.id}`} className="button">طلب الآن</Link>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Services;
