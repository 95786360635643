// TranslationExample.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './About.css';
// import aboutImage from '../assets/about.png';
import aboutImage from '../assets/sahhab2.jpeg';
import { Link } from 'react-router-dom';
// import Section from './Section';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="translation-example">
      <div className="right-section">
        <h1 className='about'>{t('about.title')}</h1>
        <h2 className='about'>{t('about.subtitle')}</h2>
        <p>{t('about.paragraph')}</p>
        <button className='about'><Link to="/subscriptions" style={{color:"white"}}>{t('about.buttonText')}</Link></button>
      </div>
      <div className="left-section">
        <div className="image-container">
          <img src={aboutImage} alt="Example" className="image" />
        </div>
      </div>
      {/* <Section /> */}
    </div>
  );
};

export default About;
