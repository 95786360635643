// src/components/ServicePages/WpsServices.js
import React from 'react';
import products from '../../productsData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faFileAlt, faCheckCircle, faTools } from '@fortawesome/free-solid-svg-icons';

const WpsServices = () => {
  const wpsProducts = products.filter(product => product.category === 'منصة مدد').slice(0, 4);

  return (
    <div className="service-page">
      <h1>نظام حماية الأجور (WPS)</h1>
      <p>نظام حماية الأجور (WPS) هو نظام يضمن دفع الأجور للموظفين في الوقت المناسب وبالكامل وفقًا للقوانين واللوائح. يهدف هذا النظام إلى زيادة الشفافية في عمليات الدفع وتقليل النزاعات المتعلقة بالأجور.</p>

      <div className="intro-section">
        <img src="https://via.placeholder.com/600x400" alt="نظام حماية الأجور" className="intro-image" />
        <p>
          <strong>خدمات منصة مدد:</strong> 
          منصة مدد تقدم حلولاً شاملة لإدارة الأجور وتقديم التقارير وضمان الامتثال لنظام حماية الأجور. تشمل الخدمات التسجيل في منصة مدد، رفع الرواتب بشكل شهري، تحديث بيانات الموظفين، وإزالة ملاحظات حماية الأجور.
        </p>
      </div>

      <div className="service-icons">
        <FontAwesomeIcon icon={faShieldAlt} className="service-icon" />
      </div>

      <div className="steps">
        <h2>خطوات التسجيل في نظام حماية الأجور</h2>
        <div className="steps-list">
          <div className="step">
            <FontAwesomeIcon icon={faFileAlt} className="step-icon" />
            <h3>الخطوة 1</h3>
            <p>تقديم الطلب</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faTools} className="step-icon" />
            <h3>الخطوة 2</h3>
            <p>إعداد النظام</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faCheckCircle} className="step-icon" />
            <h3>الخطوة 3</h3>
            <p>استكمال الإجراءات</p>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faShieldAlt} className="step-icon" />
            <h3>الخطوة 4</h3>
            <p>بدء الاستخدام</p>
          </div>
        </div>
      </div>

      <h2>خدمات منصة مدد</h2>
      <div className="products-list">
        {wpsProducts.map(product => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>السعر: {product.price} ريال</p>
            <p>المدة: {product.duration}</p>
            <button className="request-button">طلب الخدمة</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WpsServices;
