// src/components/ServicePages/ConsultationServices.js
import React from 'react';
import products from '../../productsData'; // تعديل المسار للاستيراد بشكل صحيح
import './ConsultationServices.css'; // تعديل المسار للاستيراد بشكل صحيح
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faGavel, faBalanceScale, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

const ConsultationServices = () => {
  const consultationProducts = products.filter(product => product.category === 'الاستشارات');

  return (
    <div className="service-page">
      <h1>خدمات الاستشارات القانونية</h1>
      <p>نوفر مجموعة واسعة من خدمات الاستشارات القانونية لدعم الأفراد والشركات في مختلف المجالات. فريقنا المتخصص يقدم الاستشارات اللازمة لضمان الامتثال القانوني وحماية مصالحك.</p>
      
      <div className="intro-section">
        <img src="https://via.placeholder.com/600x400" alt="خدمات الاستشارات القانونية" className="intro-image" />
        <p>
          <strong>أهمية الاستشارات القانونية:</strong> 
          في عالم الأعمال اليوم، تعتبر الاستشارات القانونية جزءًا أساسيًا من نجاح واستدامة الشركات. نقدم استشارات قانونية في مختلف المجالات مثل العقود، والتوظيف، وحماية الملكية الفكرية، والامتثال القانوني، والضرائب. يضمن فريقنا تقديم حلول قانونية مبتكرة وفعالة.
        </p>
      </div>

      <div className="service-icons">
        <FontAwesomeIcon icon={faHandshake} className="service-icon" />
        <FontAwesomeIcon icon={faGavel} className="service-icon" />
        <FontAwesomeIcon icon={faBalanceScale} className="service-icon" />
        <FontAwesomeIcon icon={faShieldAlt} className="service-icon" />
      </div>

      <div className="services-list">
        <h2>خدماتنا تشمل:</h2>
        <ul>
          <li>الاستشارات في إعداد وتفسير العقود</li>
          <li>استشارات قانونية في مجال العمل والتوظيف</li>
          <li>حماية الملكية الفكرية</li>
          <li>الامتثال للقوانين واللوائح المحلية والدولية</li>
          <li>الاستشارات الضريبية</li>
          <li>حل النزاعات والتحكيم</li>
          <li>التمثيل القانوني في المحاكم</li>
        </ul>
      </div>

      <h2>خدمات الاستشارات</h2>
      <div className="products-list">
        {consultationProducts.slice(0, 4).map(product => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>السعر: {product.price} ريال</p>
            <p>المدة: {product.duration}</p>
            <button className="request-button">طلب الخدمة</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConsultationServices;
