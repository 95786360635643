// src/components/ProductDetails.js
// import React from 'react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import products from '../productsData'; // استيراد البيانات
import './ProductDetails.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import HeroSection from './HeroSection';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons
const ProductDetails = () => {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  // 
  const { productId } = useParams();
  const product = products.find((product) => product.id === parseInt(productId));

  if (!product) {
    return <div>المنتج غير موجود</div>;
  }

  const handleRequest = () => {
    window.location.href = `https://wa.me/966554061714?text=أرغب في طلب ${product.name} ومدة الخدمة ${product.duration}.`;
  };

  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="product-details">
      <img src={product.image} alt={product.name} className="product-image" />
      <h2 className="product-name">{product.name}</h2>
      <div className='ServicesHero'>
   
      <a href="/services"  rel="noreferrer">
      {/* <FontAwesomeIcon icon="fa-solid fa-bars" /> */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{width:"15px",padding:"10px"}}><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>
          </a>
          </div>
          <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                <FaHome /> {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/products" style={{color:"#172F4E"}}>
                <FaServicestack /> {t('services')}
              </Link>
            </li>
            </ul>
            </div>
      <table className="product-table">
        <tbody>
          <tr>
            <th>الوصف</th>
            <td>{product.description}</td>
          </tr>
          <tr>
            <th>مدة الخدمة</th>
            <td>{product.duration}</td>
          </tr>
        </tbody>
      </table>
      <button onClick={handleRequest} className="button">طلب الخدمة</button>
    </div>
    </>
  );
};

export default ProductDetails;
