import React, { useState } from 'react';
import './FAQComponent.css'; // Import CSS file for styling
import { useTranslation } from 'react-i18next';
import HeroSection from './HeroSection';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import { Link, useNavigate } from 'react-router-dom';

import { FaHome, FaServicestack, FaRegEnvelope, FaCalculator, FaQuestionCircle, FaRegListAlt } from 'react-icons/fa'; // Importing icons
// import { useTranslation } from 'react-i18next';
// import HeroSection from './HeroSection';
const FAQComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };
  //
  const [activeSection, setActiveSection] = useState('section1');

  const sections = {
    section1: {
      title: "القسم 1",
      faqs: [
        { question: "ما هو هدف هذا الموقع؟", answer: "يهدف هذا الموقع إلى تقديم معلومات حول خدماتنا وكيفية مساعدتنا." },
        { question: "كيف يمكنني الاتصال بالدعم؟", answer: "يمكنك الاتصال بفريق الدعم عبر نموذج الاتصال أو البريد الإلكتروني." },
      ]
    },
    section2: {
      title: "القسم 2",
      faqs: [
        { question: "أين يمكنني العثور على معلومات التسعير؟", answer: "معلومات التسعير متاحة على صفحة التسعير الخاصة بنا." },
        { question: "هل يمكنني تعديل معلومات حسابي؟", answer: "نعم، يمكنك تعديل معلومات حسابك من خلال صفحة إعدادات الحساب." },
      ]
    },
    section3: {
      title: "القسم 3",
      faqs: [
        { question: "كيف يمكنني تقديم طلب خدمة؟", answer: "يمكنك تقديم طلب خدمة من خلال نموذج الطلب على موقعنا." },
        { question: "ما هي خيارات الدفع المتاحة؟", answer: "نحن نقدم عدة خيارات للدفع، بما في ذلك البطاقات الائتمانية والتحويلات البنكية." },
      ]
    },
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <>
    <HeroSection
          heroSliderSettings={heroSliderSettings}
          heroSlides={heroSlides}
          splitText={splitText}
        />
    <div className="faq-page">
    <h1>{t('FAQ')}</h1>
        <div className='Bignation'>
            <ul  style={{display:"flex", listStyle:"none"}}>
          <li style={{padding:"10px"}}>
              <Link to="/" style={{color:"#172F4E"}}>
                <FaHome /> {t('HOME')}
              </Link>
            </li>
            <li style={{padding:"10px"}}>
              <Link to="/faq2" style={{color:"#172F4E"}}>
                <FaServicestack /> {t('FAQ')}
              </Link>
            </li>
            </ul>
            </div>
      <div className="faq-content">
        
        <div className="faq-sidebar">
          <h3>الأقسام</h3>
          <ul>
            {Object.keys(sections).map(sectionKey => (
              <li key={sectionKey}>
                <a
                  href={`#${sectionKey}`}
                  onClick={() => handleSectionChange(sectionKey)}
                >
                  {sections[sectionKey].title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="faq-section">
          <h2>{sections[activeSection].title}</h2>
          <div className="faq-list">
            {sections[activeSection].faqs.map((faq, index) => (
              <div key={index} className={`faq-item`}>
                <div className="faq-question">
                  {faq.question}
                </div>
                <div className="faq-answer">
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default FAQComponent;
