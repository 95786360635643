import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import products from '../productsData'; 
import logos from '../logosData'; 
import './Serviceshome.css';
import heroImage1 from '../assets/banner2.jpeg';
import heroImage2 from '../assets/banner2.jpeg';
import heroImage3 from '../assets/banner2.jpeg';
import { useTranslation } from 'react-i18next';  
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faGavel, faShieldAlt, faTools, faUserShield, faBuilding, faTrademark, faMoneyCheckAlt, faBalanceScale, faUserCog, faHandsHelping, faHandshake } from '@fortawesome/free-solid-svg-icons';
const categories = [...new Set(products.map((product) => product.category))];  

const Services = () => {
  
  const { t } = useTranslation();
 
  const [carouselIndex, setCarouselIndex] = useState(0);
 
  const handleBeforeChange = (oldIndex, newIndex) => {
    setCarouselIndex(newIndex);
  };
  //
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCardClick = (url) => {
    navigate(url);
  };
  const splitText = (text, wordsPerLine) => {
    const words = text.split(' ');
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(' '));
    }
    return lines.join('<br />');
  };
  const heroSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: handleBeforeChange,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', flexDirection: 'column' }}>
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: carouselIndex === i ? '#000' : '#ccc', borderRadius: '50%', marginBottom: '10px', color: '#fff' }}>
        {i + 1}
      </div>
    )
  };

  const heroSlides = [
    {
      image: heroImage1,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage2,
      title: t(''),
      subtitle: t(''),
    },
    {
      image: heroImage3,
      title: t(''),
      subtitle: t(''),
    },
  ];

  // const handleCardClick = (path) => {
  //   navigate(path);
  // };
  // 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const selectedCardRef = useRef(null);

  useEffect(() => {
    if (selectedCardRef.current) {
      selectedCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedCategory]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
  };

  const filteredServices = selectedCategory
    ? products.filter((service) => service.category === selectedCategory)
    : [];

  return (
  

    <div className="serviceshome">
     
      {/* <h3 style={{color:'#00324f', textAlign:"center"}} className='services-title'>{t("servicesSection.title")}</h3> */}
      <h1 onClick={toggleDropdown} style={{cursor: 'pointer'}}>
        {t("servicesSection.subtitle")}
        {/* <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" style={{ marginLeft: '10px' }} /> */}
        {/* <FontAwesomeIcon icon="fa-sharp fa-solid fa-bars" /> */}
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round" style={{}}>
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M4 6l16 0" />
  <path d="M4 12l16 0" />
  <path d="M4 18l16 0" />
</svg>
      </h1>
      {dropdownOpen && (
     <div>
      {selectedCategory && <div className="overlay active" onClick={() => setSelectedCategory(null)}></div>}
      <div className="categories-container">
        <div className="categories-list">
          {categories.map((category) => (
            <motion.div
              key={category}
              className={`category-card-home ${category === selectedCategory ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category)}
              layout
              initial={{ borderRadius: "12px" }}
              animate={{ borderRadius: "12px", width: category === selectedCategory ? "100%" : "calc(33.33% - 20px)" }}
              transition={{ duration: 0.3 }}
              ref={category === selectedCategory ? selectedCardRef : null}
            >
              <img src={logos[category] || logos.default} alt={category} className="category-logo-home" />
              <p>{category}</p>
              <AnimatePresence>
                {category === selectedCategory && (
                  <motion.div
                    className="services-dropdown"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="services-grid">
                      {filteredServices.map((service) => (
                        <div key={service.id} className="service-item">
                          <h3>{service.name}</h3>
                          <Link to={`/service/${service.id}`} className="button">طلب الآن</Link>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
        </div>
      </div>
      )}
      </div>
  
  );
};

export default Services;
